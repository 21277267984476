import { useTranslation } from "react-i18next";
import styles from "./style.module.css";
import { Box, Stack, Typography } from "@mui/material";

function Card({ image, title, description }) {
  const { t } = useTranslation();

  return (
    <Stack
      // className='practices-card'
      className={styles.practices_plan_card}
      style={{
        borderRadius: { xs: "8.533333333vw", sm: "2vw", lg: "32px" },
        boxShadow: "0px 4px 20px rgba(19, 61, 80, 0.1)",
      }}
    >
      <Stack
        sx={{
          padding: {
            xs: "10.66666667vw 8.533333333vw",
            sm: "2.5vw 2vw",
            lg: "40px 32px",
          },
          color: "#0F3C50",
        }}
      >
        {image && (
          <Box
            component="img"
            src={image}
            alt={`${title} logo`}
            className={styles["logo"]}
            sx={{ marginBottom: {xs: "6.4vw", sm: "1.5vw", lg: "24px"} }}
          />
        )}
        <p className={styles.practiceTextTitle}>{t(title)}</p>
        <p className={styles.practiceTextDescription}>{t(description)}</p>
      </Stack>
    </Stack>
  );
}

export default Card;
