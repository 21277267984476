import React from "react";
import styles from "./style.module.css";
import Cover from "../../../Assets/Images/about-us-mission-cover.png";
import Fabien from "../../../Assets/Images/fabien.png";
import Olivier from "../../../Assets/Images/olivier.png";
import { Grid } from "@mui/material";
import { ArrowLeft, WhatsApp } from "@mui/icons-material";
import {useTranslation} from "react-i18next";

const AboutUsFounder = () => {
  const {t} = useTranslation()
  const FounderContent = ({
    name,
    nameSP,
    image,
    imagePosition,
    whatsApp,
    whatsAppText,
    email,
    content,
  }) => (
    <div>
      <div className={styles.aboutUsFounderContainer}>
        <div
          style={{
            backgroundColor: "#F0F9F4",
            display: "inline-block",
            borderRadius: "10px",
            marginTop: "16vw",
          }}
        >
          <div
            className={[styles.aboutUsFounderTextTitle, styles.sp].join(" ")}
            dangerouslySetInnerHTML={{ __html: nameSP }}
          />
        </div>
        <figure
          className={styles.AboutUsFounderMedia}
          style={{ order: imagePosition === "right" ? 1 : 0 }}
        >
          <img
            className={styles.aboutUsFounderImage}
            src={image}
            alt="Founder"
          />
        </figure>
        <div className={styles.aboutUsFounderText}>
          <div
            style={{
              backgroundColor: "#F0F9F4",
              display: "inline-block",
              padding: "10px",
              marginBottom: "32px",
            }}
            className={[styles[imagePosition], styles.pc].join(" ")}
          >
            <p
              className={[styles.aboutUsFounderTextTitle, styles.pc].join(" ")}
            >
              {t(name)}
            </p>
          </div>
          <Grid
            container
            xs={12}
            spacing={{xs: "2.6666666667vw", sm: "3.125vw", lg: "50px" }}
          >
            {content.map((item, index) => (
              <Grid item xs={12} sm={6}>
                <div
                  className={[
                    styles.aboutUsFounderTextContainer,
                    styles[imagePosition],
                  ].join(" ")}
                >
                  <h3 className={styles.aboutUsFounderTextSubtitle}>
                    {t(item.subtitle)}
                  </h3>
                  <p className={styles.aboutUsFounderTextDescription} dangerouslySetInnerHTML={{ __html: t(item.description)}}>
                  </p>
                </div>
              </Grid>
            ))}
          </Grid>
          <Grid item xs={12} md={12} direction={'row'} spacing={{'xs': '4.8vw', 'sm': '1.125vw', 'lg': '18px'}}>
            <div
              className={[
                styles.aboutUsFounderSocialButtons,
                styles[imagePosition],
              ].join(" ")}
            >
              <button
                className={styles.aboutUsFounderWhatsApp}
                style={{ cursor: "pointer", fontFamily: 'poppins' }}
                onClick={() =>
                  (window.location.href = `https://wa.me/${whatsApp}`)
                }
              >
                <span style={{display: 'flex'}}>
                  <WhatsApp
                    color="#0f3c50"
                    sx={{
                      fontSize: { xs: "5.33333333vw", sm: "1.25vw", lg: "20px" },
                    }}
                  />
                </span>
                {whatsAppText}
              </button>
              <button
                className={styles.aboutUsFounderEmail}
                style={{ cursor: "pointer", fontFamily: 'poppins' }}
                onClick={() => (window.location.href = `mailto:${email}`)}
              >
                {email}
              </button>
            </div>
          </Grid>
        </div>
      </div>
    </div>
  );

  return (
    <div className={styles.aboutUsFounderMainContainer}>
      <div className={styles.aboutUsFounderSectionTitle}>{t("Les fondateurs")}</div>
      {founderContentData.map((founder, index) => (
        <FounderContent
          key={index}
          name={founder.name}
          nameSP={t(founder.nameSP)}
          image={founder.image}
          imagePosition={founder.imagePosition}
          whatsApp={founder.whatsApp}
          whatsAppText={t(founder.whatsAppText)}
          email={founder.email}
          content={founder.content}
        />
      ))}
    </div>
  );
};

export default AboutUsFounder;

const founderContentData = [
  {
    name: "Fabien Obadia, co-fondateur",
    nameSP: "Fabien Obadia,<br/>co-fondateur",
    image: Fabien,
    email: "fob@peppermint.company",
    whatsApp: "+33612821114",
    whatsAppText: "Discussion directe avec Fabien",
    imagePosition: "right",
    content: [
      {
        subtitle: "Formation d’ingénieur en informatique",
        description:
          "Peppermint est sa nouvelle aventure entrepreneuriale. Il est avec Olivier, l'acteur de la croissance du groupe. Ses résultats dignes d'une licorne sont révélateurs de la pertinence de son modèle.",
        descriptionSP:
          "Peppermint est sa nouvelle aventure entrepreneuriale. il est avec Olivier, l’acteur de la croissance du groupe qui est digne d’une licorne est le révélateur de la pertinence de son modèle."
      },
      {
        subtitle: "20 ans d’expérience",
        description:
          "en stratégie, IT et innovation et management de projets d’envergures et 10 ans dans l’agile, Peppermint est sa 4 ème aventure entrepreneuriale sur les 10 dernières années.",
        descriptionSP:
          "en stratégie, IT et innovation et management de projets d’envergures et 10 ans dans l’agile, Peppermint est sa 4e aventure entrepreneuriale sur les 10 dernières années."
      },
      {
        subtitle: "Parcours international",
        description:
          `notamment en Europe et aux États-Unis. Fabien a crée des filiales d’entreprises françaises à l’étranger, lançant avec succès leurs services sur le marché local.`,
        descriptionSP:
          "notamment en Europe et aux États Unis. Fabien a créer des filiales d’entreprise française à l’étranger, lançant avec succès leurs services sur le marché local."
      },
      {
        subtitle: "Auteur du cadre méthodologique agile",
        description:
          "propre à Peppermint, il allie culture du résultat et de la croissance, agilité et cross disciplinarité.",
        descriptionSP:
          "de Peppermint alliant culture du résultat et de la croissance, agilité et cross disciplinarité."
      },
    ],
  },
  {
    name: "Olivier Waldin, co-fondateur",
    nameSP: "Olivier Waldin,<br/>co-fondateur",
    image: Olivier,
    email: "olivier.waldin@peppermint.company",
    whatsApp: "+33603903906",
    whatsAppText: "Discussion directe avec Olivier",
    imagePosition: "left",
    content: [
      {
        subtitle: "Formation d’ingénieur en informatique",
        description:
          "Peppermint est sa nouvelle aventure entrepreneuriale. Il est avec Fabien, l'acteur de la croissance du groupe. Ses résultats dignes d'une licorne sont révélateurs de la pertinence de son modèle.",
        descriptionSP:
          "Peppermint est sa nouvelle aventure entrepreneuriale, il est avec Fabien, l’acteur de la croissance du groupe qui est digne d’une licorne est le révélateur de la pertinence de son modèle."
      },
      {
        subtitle: "15 ans d’expérience",
        description:
          "en transformation des entreprises et des business avec de fortes dimensions métiers, stratégiques et  Marketing.",
        descriptionSP:
          "en transformation des entreprises et des business avec de fortes dimensions métier, stratégie et  Marketing",
      },
      {
        subtitle: "Entrepreneur aguerri et précurseur de l’intrapreneuriat",
        description:
          "il est à l’origine de la transformation digitale de Point.P et le fondateur de la startup Homly You du groupe Saint-Gobain.",
        descriptionSP:
          "il est à l’origine de la transformation digitale de Point.P et le fondateur de la startup Homly You du groupe Saint Gobain",
      },
      {
        subtitle: "Reconnu en France",
        description:
          "comme l’un des meilleurs spécialistes de la transformation par la valeur business que ce soit pour les startups, les ETI ou les grands groupes.",
        descriptionSP:
          "comme l’un des meilleurs spécialistes de la transformation par la valeur business que ce soit pour les startups, les ETI ou les grands groupes."
      },
    ],
  },
];
