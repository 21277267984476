import { BrowserRouter, Routes, Route } from "react-router-dom";

import FirstLayout from "../Pages/Layouts/FirstLayout";
import SecondLayout from "../Pages/Layouts/SecondLayout";

import {
  Home,
  AboutUs,
  ContactUsPage,
  CGU,
  CookiesPolicy,
  PrivacyPolicy,
} from "../Pages";

import ScrollToTop from "../Components/ScrollToTop";
import AnalyticsInjector from "../Components/AnalyticsInjector";
import HotjarInjector from "../Components/HotjarInjector";
import QuantcastInjector from "../Components/QuantcastInjector";

const Navigations = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <HotjarInjector />
      <AnalyticsInjector />
      <QuantcastInjector />
      <Routes>
        <Route element={<FirstLayout />}>
          <Route index element={<Home />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/contact" element={<ContactUsPage />} />
        </Route>
        <Route element={<SecondLayout />}>
          <Route path="/cgu" element={<CGU />} />
          <Route path="/cookies-policy" element={<CookiesPolicy />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Navigations;
