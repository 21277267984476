import { useTranslation } from "react-i18next";
import React from "react";
import style from "./style.module.css";
import WrongIcon from "../../Assets/svg/wrong-icon.svg";
import CheckIcon from "../../Assets/svg/check-icon.svg";
import axios from "axios";
import { CircularProgress } from "@mui/material";

export default function ContactUs() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isSubmit, setIsSubmit] = React.useState(false);
  const [firstName, setFirstName] = React.useState("");
  const [isFirstNameCheck, setIsFirstNameCheck] = React.useState(false);
  const [isLastNameCheck, setIsLastNameCheck] = React.useState(false);
  const [isPhoneNumberCheck, setIsPhoneNumberCheck] = React.useState(false);
  const [isMessageCheck, setIsMessageCheck] = React.useState(false);
  const [isSelectedValueCheck, setIsSelectedValueCheck] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [firstNameStatus, setFirstNameStatus] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [emailStatus, setEmailStatus] = React.useState(false);
  const [isCheck, setIsCheck] = React.useState(false);
  const [isAgreementCheck, setIsAgreementCheck] = React.useState(false);
  const [errorAgreement, setErrorAgreement] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState("");
  const [sentFailed, setSentFailed] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const CheckMailFormat = (value) => {
    const mailRegex = /^(([^<>()[\],;:\s@]+(\.[^<>()[\],;:\s@]+)*)|(.+))@(([^<>()[\],;:\s@]+\.)+[^<>()[\],;:\s@]{2,})$/i;
    setEmailStatus(mailRegex.test(value));
  };

  const Submit = () => {
    if (isAgreementCheck && firstName !== "" && email !== "" && lastName !== "" && phoneNumber !== "" && selectedValue !== "" && message !== "") {
      setIsLoading(true);
      axios
        .post("https://www.peppermint.company/peppermint-webapi/api/v1/email", {
          email: email,
          firstName: firstName,
          lastName: lastName,
          phoneNumber: phoneNumber,
          message: message,
          type: selectedValue,
          subject: "Contact For Peppermint Company",
        })
        .then((res) => {
          if (res.data === 200) {
            setIsSubmit(true);
          } else {
            setErrorMessage(res.data);
            setSentFailed(true);
            setIsSubmit(false);
          }
          setIsLoading(false);
        })
        .catch((e) => {
          setErrorMessage("500 request timeout");
          setSentFailed(true);
          setIsSubmit(false);
          setIsLoading(false);
        });
    } else {
      setIsCheck(true);
      setIsFirstNameCheck(true);
      setIsLastNameCheck(true);
      setIsMessageCheck(true);
      setIsSelectedValueCheck(true);
      setIsPhoneNumberCheck(true);
      setErrorAgreement(true);
    }
  };

  return (
    <div className={style.contactUs}>
      <div className={style.contactUs__titleBox}>
        <p className={style.contactUs__title}>{t("Restons en contact")}</p>
      </div>
      <div className={style.contactUs__titleBoxMobile}>
        <p className={style.contactUs__title}>{t(" Restons ")}</p>
        <p className={style.contactUs__title}>{t(" en contact ")}</p>
      </div>
      <div className={style.contactUs__container}>
        {isSubmit ? (
          <div
            style={{
              height: "636px",
              width: "1136px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              fontSize: "40px",
              fontWeight: 700,
              textAlign: "center",
              textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            }}
          >
            <p style={{ fontFamily: "poppins" }}>{t("Votre message a bien été envoyé.")}</p>
            <p>{t("Merci & À bientôt !")}</p>
          </div>
        ) : (
          <>
            <div className={style.contactUs__checkBoxContainerMobile}>
              <div className={style.contactUs__checkBox}>
                <input
                  value={"Projet"}
                  type="checkbox"
                  className={style.contactUs__checkMark}
                  checked={selectedValue === "Projet"}
                  onChange={handleChange}
                />
                <span style={{ fontWeight: selectedValue === "Projet" ? 700 : 500, fontSize: "14px" }}>{t("Projet")}</span>
              </div>
              <div className={style.contactUs__checkBox}>
                <input
                  value={"Partenariat"}
                  type="checkbox"
                  className={style.contactUs__checkMark}
                  checked={selectedValue === "Partenariat"}
                  onChange={handleChange}
                />
                <span style={{ fontWeight: selectedValue === "Partenariat" ? 700 : 500, fontSize: "14px" }}>{t("Partenariat")}</span>
              </div>
              <div className={style.contactUs__checkBox}>
                <input
                  value={"Autre"}
                  type="checkbox"
                  className={style.contactUs__checkMark}
                  checked={selectedValue === "Autre"}
                  onChange={handleChange}
                />
                <span style={{ fontWeight: selectedValue === "Autre" ? 700 : 500, fontSize: "14px" }}>{t("Autre")}</span>
              </div>
              <div
                className={style.contactUs__checkBoxWarningMobile}
                style={{
                  paddingTop: selectedValue !== "" ? 0 : "20px",
                  flexDirection: "row",
                  width: "fit-content",
                  padding: 0,
                  margin: 0,
                }}
              >
                {isSelectedValueCheck ? (
                  selectedValue !== "" ? (
                    ""
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        height: "100%",
                        alignItems: "center",
                      }}
                    >
                      <p
                        style={{
                          color: "#FDAE62",
                          padding: 0,
                          margin: 0,
                        }}
                      >
                        {t("s'il vous plait sélectionner en un")}
                      </p>
                      <img
                        alt={"wrong-icon"}
                        src={WrongIcon}
                        style={{
                          position: "relative",
                          width: "20px",
                          height: "20px",
                          paddingLeft: "16px",
                        }}
                      />
                    </div>
                  )
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className={style.contactUs__leftPart}>
              <div>
                <p className={style.contactUs__inputTitle}>{t("Prénom")}</p>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <input
                    onBlur={(e) => {
                      setIsFirstNameCheck(true);
                      if (e.target.value === "") {
                        setFirstNameStatus(false);
                      } else {
                        setFirstNameStatus(true);
                      }
                    }}
                    onChange={(e) => {
                      setFirstName(e.target.value);
                    }}
                    style={{
                      borderColor: isFirstNameCheck ? (firstNameStatus ? "#EBEB78" : "#FDAE62") : "#0F3C50",
                    }}
                    className={style.contactUs__inputField}
                    type={"text"}
                  />
                  {isFirstNameCheck ? (
                    firstNameStatus ? (
                      <div
                        style={{
                          height: "100%",
                          alignItems: "center",
                          paddingLeft: "1vw",
                        }}
                      >
                        <img
                          alt={"check-icon"}
                          src={CheckIcon}
                          style={{
                            position: "relative",
                            width: "20px",
                            height: "20px",
                          }}
                        />
                      </div>
                    ) : (
                      <div
                        style={{
                          height: "100%",
                          alignItems: "center",
                          paddingLeft: "8px",
                        }}
                      >
                        <img
                          alt={"wrong-icon"}
                          src={WrongIcon}
                          style={{
                            position: "relative",
                            width: "20px",
                            height: "20px",
                          }}
                        />
                      </div>
                    )
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div>
                <p className={style.contactUs__inputTitle}>{t("Nom de famille")}</p>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <input
                    onBlur={() => {
                      setIsLastNameCheck(true);
                    }}
                    onChange={(e) => {
                      setLastName(e.target.value);
                    }}
                    style={{
                      borderColor: isLastNameCheck ? (lastName !== "" ? "#EBEB78" : "#FDAE62") : "#0F3C50",
                    }}
                    className={style.contactUs__inputField}
                    type={"text"}
                  />
                  {isLastNameCheck ? (
                    lastName !== "" ? (
                      <div
                        style={{
                          height: "100%",
                          alignItems: "center",
                          paddingLeft: "1vw",
                        }}
                      >
                        <img
                          alt={"check-icon"}
                          src={CheckIcon}
                          style={{
                            position: "relative",
                            width: "20px",
                            height: "20px",
                          }}
                        />
                      </div>
                    ) : (
                      <div
                        style={{
                          height: "100%",
                          alignItems: "center",
                          paddingLeft: "8px",
                        }}
                      >
                        <img
                          alt={"wrong-icon"}
                          src={WrongIcon}
                          style={{
                            position: "relative",
                            width: "20px",
                            height: "20px",
                          }}
                        />
                      </div>
                    )
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div>
                <p className={style.contactUs__inputTitle}>{t("Adresse e-mail")}</p>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <input
                    onChange={(e) => {
                      setEmail(e.target.value);
                      CheckMailFormat(e.target.value);
                    }}
                    onBlur={(e) => {
                      setIsCheck(true);
                      if (e.target.value !== "") {
                        CheckMailFormat(e.target.value);
                      } else {
                        setEmailStatus(false);
                      }
                    }}
                    className={style.contactUs__inputField}
                    style={{
                      borderColor: isCheck ? (emailStatus ? "#EBEB78" : "#FDAE62") : "#0F3C50",
                    }}
                    type={"email"}
                  />
                  {isCheck ? (
                    emailStatus ? (
                      <div
                        style={{
                          height: "100%",
                          alignItems: "center",
                          paddingLeft: "1vw",
                        }}
                      >
                        <img
                          alt={"check-icon"}
                          src={CheckIcon}
                          style={{
                            position: "relative",
                            width: "20px",
                            height: "20px",
                          }}
                        />
                      </div>
                    ) : (
                      <div
                        style={{
                          height: "100%",
                          alignItems: "center",
                          paddingLeft: "8px",
                        }}
                      >
                        <img
                          alt={"wrong-icon"}
                          src={WrongIcon}
                          style={{
                            position: "relative",
                            width: "20px",
                            height: "20px",
                          }}
                        />
                      </div>
                    )
                  ) : (
                    ""
                  )}
                </div>
                {isCheck && !emailStatus ? (
                  <p
                    style={{
                      padding: "5px 5px 0 5px",
                      margin: 0,
                      fontSize: "14px",
                      fontWeight: 600,
                      lineHeight: "21px",
                      color: "#FDAE62",
                    }}
                  >
                    {t("Veuillez renseigner une adresse e-mail correcte")}
                  </p>
                ) : (
                  ""
                )}
              </div>
              <div>
                <p className={style.contactUs__inputTitle}>{t("Numéro de téléphone")}</p>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <input
                    onBlur={() => {
                      setIsPhoneNumberCheck(true);
                    }}
                    onChange={(e) => {
                      setPhoneNumber(e.target.value);
                    }}
                    style={{
                      borderColor: isPhoneNumberCheck ? (phoneNumber !== "" ? "#EBEB78" : "#FDAE62") : "#0F3C50",
                    }}
                    className={style.contactUs__inputField}
                    type={"tel"}
                  />
                  {isPhoneNumberCheck ? (
                    phoneNumber !== "" ? (
                      <div
                        style={{
                          height: "100%",
                          alignItems: "center",
                          paddingLeft: "1vw",
                        }}
                      >
                        <img
                          alt={"check-icon"}
                          src={CheckIcon}
                          style={{
                            position: "relative",
                            width: "20px",
                            height: "20px",
                          }}
                        />
                      </div>
                    ) : (
                      <div
                        style={{
                          height: "100%",
                          alignItems: "center",
                          paddingLeft: "8px",
                        }}
                      >
                        <img
                          alt={"wrong-icon"}
                          src={WrongIcon}
                          style={{
                            position: "relative",
                            width: "20px",
                            height: "20px",
                          }}
                        />
                      </div>
                    )
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className={style.contactUs__rightPart}>
              <div>
                <p className={style.contactUs__inputTitle}>{t("Message")}</p>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <textarea
                    onBlur={() => {
                      setIsMessageCheck(true);
                    }}
                    onChange={(e) => {
                      setMessage(e.target.value);
                    }}
                    style={{
                      borderColor: isMessageCheck ? (message !== "" ? "#EBEB78" : "#FDAE62") : "#0F3C50",
                    }}
                    className={style.contactUs__messageInputField}
                    wrap="hard"
                  />
                  {isMessageCheck ? (
                    message !== "" ? (
                      <div
                        style={{
                          height: "100%",
                          alignItems: "center",
                          paddingLeft: "1vw",
                        }}
                      >
                        <img
                          alt={"check-icon"}
                          src={CheckIcon}
                          style={{
                            position: "relative",
                            width: "20px",
                            height: "20px",
                          }}
                        />
                      </div>
                    ) : (
                      <div
                        style={{
                          height: "100%",
                          alignItems: "center",
                          paddingLeft: "8px",
                        }}
                      >
                        <img
                          alt={"wrong-icon"}
                          src={WrongIcon}
                          style={{
                            position: "relative",
                            width: "20px",
                            height: "20px",
                          }}
                        />
                      </div>
                    )
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div
                className={style.contactUs__checkBoxWarning}
                style={{
                  paddingTop: selectedValue !== "" ? 0 : "20px",
                  flexDirection: "row",
                  width: "fit-content",
                  padding: 0,
                  margin: 0,
                }}
              >
                {isSelectedValueCheck ? (
                  selectedValue !== "" ? (
                    ""
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        height: "100%",
                        alignItems: "center",
                      }}
                    >
                      <p
                        style={{
                          color: "#FDAE62",
                          padding: "10px 0 0 0",
                          margin: 0,
                        }}
                      >
                        {t("veuillez en sélectionner un ci-dessous")}
                      </p>
                      <img
                        alt={"wrong-icon"}
                        src={WrongIcon}
                        style={{
                          position: "relative",
                          width: "20px",
                          height: "20px",
                          paddingLeft: "16px",
                          paddingTop: "10px",
                        }}
                      />
                    </div>
                  )
                ) : (
                  ""
                )}
              </div>
              <div className={style.contactUs__checkBoxContainer}>
                <div className={style.contactUs__checkBox}>
                  <input
                    value={"Projet"}
                    type="checkbox"
                    className={style.contactUs__checkMark}
                    checked={selectedValue === "Projet"}
                    onChange={handleChange}
                  />
                  <span style={{ fontWeight: selectedValue === "Projet" ? 700 : 500, fontSize: "14px" }}>{t("Projet")}</span>
                </div>
                <div className={style.contactUs__checkBox}>
                  <input
                    value={"Partenariat"}
                    type="checkbox"
                    className={style.contactUs__checkMark}
                    checked={selectedValue === "Partenariat"}
                    onChange={handleChange}
                  />
                  <span style={{ fontWeight: selectedValue === "Partenariat" ? 700 : 500, fontSize: "14px" }}>{t("Partenariat")}</span>
                </div>
                <div className={style.contactUs__checkBox}>
                  <input
                    value={"Autre"}
                    type="checkbox"
                    className={style.contactUs__checkMark}
                    checked={selectedValue === "Autre"}
                    onChange={handleChange}
                  />
                  <span style={{ fontWeight: selectedValue === "Autre" ? 700 : 500, fontSize: "14px" }}>{t("Autre")}</span>
                </div>
              </div>
              <div className={style.contactUs__agreementContainer}>
                <div className={style.contactUs__agreement}>
                  <div style={{ width: "30px" }}>
                    <input
                      type="checkbox"
                      style={{ width: "30px" }}
                      className={
                        errorAgreement
                          ? isAgreementCheck
                            ? style.contactUs__checkMark
                            : style.contactUs__checkMarkError
                          : style.contactUs__checkMark
                      }
                      onClick={() => setIsAgreementCheck(!isAgreementCheck)}
                    />
                  </div>
                  <div>
                    <span
                      style={{
                        color: errorAgreement ? (isAgreementCheck ? "#0F3C50" : "#FDAE62") : "#0F3C50",
                      }}
                    >
                      {t(
                        `En cliquant sur “Envoyer ma demande”, vous acceptez que les informations recueillies à partir de ce formulaire soient transmises à nos équipes pour gérer votre demande. `
                      )}
                    </span>
                    <span
                      style={{
                        color: errorAgreement ? (isAgreementCheck ? "#0F3C50" : "#FDAE62") : "#0F3C50",
                        fontWeight: 500,
                      }}
                    >
                      <br />
                      <a href="https://www.peppermint.company/privacy-policy" target="_blank">
                        {t(`Cliquez ici`)}
                      </a>
                      {t(` pour en savoir plus sur notre politique de confidentialité.`)}
                    </span>
                  </div>
                  {errorAgreement ? (
                    isAgreementCheck ? (
                      ""
                    ) : (
                      <div
                        style={{
                          height: "100%",
                          alignItems: "center",
                          paddingLeft: "1vw",
                        }}
                      >
                        <img
                          alt={"wrong-icon"}
                          src={WrongIcon}
                          style={{
                            position: "relative",
                            width: "20px",
                            height: "20px",
                          }}
                        />
                      </div>
                    )
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className={style.contactUs__submitButtonContainer}>
                <button className={style.contactUs__submitButton} onClick={() => Submit()}>
                  {isLoading ? <CircularProgress size={20} /> : t("Envoyer ma demande")}
                </button>
              </div>
              {sentFailed ? (
                <>
                  <p className={style.contactUs__errorText} style={{ marginTop: "10px" }}>
                    {t("L'envoi de l'e-mail a échoué, ")}
                  </p>
                  <p className={style.contactUs__errorText}>{t("code d'état:") + " " + t(errorMessage)}</p>
                  <p className={style.contactUs__errorTextMobile}>{t("L'envoi de l'e-mail a échoué, code d'état: ") + t(errorMessage)}</p>
                </>
              ) : (
                ""
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
