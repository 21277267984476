import * as React from "react";
import { styled } from "@mui/material/styles";
import style from "./style.module.css";

import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

import { ReactComponent as ExpendIcon } from "../../Assets/Icons/expend.svg";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    component="h2"
    expandIcon={<ExpendIcon className={style.expandIcon} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "#fff",
  height: {sx: "13.33333333vw", sm: "3.125vw", lg: "50px"},
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
}));

export default function AccordionPrivacy({
  expanded = false,
  title = "Title",
  description = "Description",
}) {
  return (
    <div className={style.component}>
      <Accordion
        defaultExpanded={expanded}
        sx={{
          "& .MuiAccordionSummary-root": {
            px: 0,
            mb: {xs: "2.666666667vw", sm: "0.625vw", lg: "10px"},
            height: "unset",
          },
          "& .MuiAccordionDetails-root": {
            py: 0,
          },
          "& .MuiAccordionSummary-content": {
            pl: {xs: "2.666666667vw", sm: "0.625vw", lg: "10px"},
          },
          "& .MuiAccordionSummary-content.Mui-expanded": {
            bgcolor: "#EAE981",
            p: {xs: "2.666666667vw", sm: "0.625vw", lg: "10px"},
          },
        }}
      >
        <AccordionSummary>
          <h2>{title}</h2>
        </AccordionSummary>
        <AccordionDetails className={style.descriptionContainer}>
          <div dangerouslySetInnerHTML={{ __html: description }} />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
