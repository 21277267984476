import { useState, useEffect } from "react";
import Logo from "../../Assets/svg/logo.svg";
import LogoScroll from "../../Assets/svg/logo-scroll.svg";
import style from "./style.module.css";
import FranceFlag from "../../Assets/Images/france.png";
import EnglishFlag from "../../Assets/Icons/english.svg";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import i18next from "i18next";

export default function Header(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);
  const [isFrench, setIsFrench] = useState(!localStorage.getItem("language") ? true : localStorage.getItem("language") === "fr");

  const [loading, setLoading] = useState(true);
  const [quantcastEl, setQuantcastEl] = useState(null);

  const changeLanguage = () => {
    // if (loading) return;
    setIsFrench(!isFrench);
  };

  useEffect(() => {
    if (quantcastEl) {
      setLoading(false);
    }
  }, [quantcastEl]);

  useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === "childList" && mutation.target === document.body) {
          // The body element has changed
          setQuantcastEl(document.getElementById("qc-cmp2-container"));
        }
      });
    });

    observer.observe(document.body, { childList: true });

    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    setQuantcastEl(document.getElementById("qc-cmp2-container"));
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setScrollTop(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (props.isSecond === true) {
      document.querySelector(`.${style.header}`).style.backgroundColor = "#0F3C50";
    }
    if (isOpen) {
      document.body.style.overflowY = "hidden";
      document.querySelector(`.${style.header}`).style.backgroundColor = "transparent";
      document.querySelector(`.${style.header__dropdownContent}`).style.backgroundColor = "transparent";
    }
    if (!props.isSecond && scrollTop > 50 && !isOpen) {
      document.body.style.overflowY = "auto";
      document.querySelector(`.${style.header}`).style.backgroundColor = "white";
      document.querySelector(`.${style.header__quiSommesNous}`).style.color = "#0F3C50";
      const buttons = document.querySelectorAll(`.${style.header__button}`);
      buttons.forEach((button) => {
        button.style.border = "1px solid #0F3C50";
        button.style.color = "#0F3C50";
      });
    } else if (!props.isSecond && scrollTop < 50 && !isOpen) {
      document.body.style.overflowY = "auto";
      document.querySelector(`.${style.header}`).style.backgroundColor = "transparent";
      document.querySelector(`.${style.header__quiSommesNous}`).style.color = "white";
      const buttons = document.querySelectorAll(`.${style.header__button}`);
      buttons.forEach((button) => {
        button.style.border = "1px solid white";
        button.style.color = "white";
      });
    }
  }, [isOpen, scrollTop]);

  useEffect(() => {
    if (isOpen) {
      document.querySelector(`.${style.header__closeBackground}`).style.display = "block";
      const dropdown = document.querySelector(`.${style.header__dropdownContent}`);
      dropdown.style.display = "flex";
      dropdown.style.transform = "translateY(0px)";
      dropdown.style.opacity = "1";
    } else {
      document.querySelector(`.${style.header__closeBackground}`).style.display = "none";
      const dropdown = document.querySelector(`.${style.header__dropdownContent}`);
      dropdown.style.display = "none";
      dropdown.style.transform = "translateY(-10px)";
      dropdown.style.opacity = "0";
    }
  }, [isOpen]);

  useEffect(() => {
    if (isFrench) {
      localStorage.setItem("language", "fr");
      i18next.changeLanguage("fr");
    } else {
      localStorage.setItem("language", "en");
      i18next.changeLanguage("en");
    }
  }, [isFrench]);

  return (
    <div className={style.header}>
      <div
        style={{
          alignItems: "center",
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          maxWidth: "1601px",
          margin: "0 auto",
        }}
      >
        <div className={style.header__leftPart}>
          <img
            className={style.header__logo}
            onClick={() => {
              setIsOpen(false);
              navigate("/");
            }}
            src={scrollTop > 50 && !props.isSecond && !isOpen ? LogoScroll : Logo}
            alt={"logo"}
          />
          <a onClick={() => navigate("/about-us")} className={style.header__quiSommesNous} style={{ cursor: "pointer" }}>
            {t("Qui sommes-nous")}
          </a>
        </div>
        <div className={style.header__hamburgerContainer}>
          {isOpen ? (
            <CloseIcon
              onClick={() => setIsOpen(false)}
              sx={{
                width: "35px",
                height: "35px",
                color: "white",
              }}
            />
          ) : (
            <MenuIcon
              onClick={() => setIsOpen(!isOpen)}
              sx={{
                width: "30px",
                height: "30px",
                color: (scrollTop > 50 || isOpen) && !props.isSecond ? "#3A7568" : "white",
              }}
            />
          )}
        </div>
        <div className={style.header__dropdownContainer}>
          <div className={style.header__dropdownContent}>
            <a
              onClick={() => {
                setIsOpen(false);
                navigate("/about-us");
              }}
              style={{ cursor: "pointer", fontFamily: "Poppins", fontSize: "14px" }}
              // href="/about-us"
            >
              {t("Qui sommes-nous")}
            </a>
            <a
              style={{ fontFamily: "Poppins", fontSize: "14px" }}
              onClick={() => setIsOpen(false)}
              href="https://www.linkedin.com/jobs/peppermint-company-jobs-worldwide/"
              target="_blank"
            >
              {t("Recrutement")}
            </a>
            <button
              onClick={() => {
                setIsOpen(false);
                navigate("/contact");
              }}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                padding: "16px 16px",
                width: "fit-content",
                height: "21px",
                background: "transparent",
                borderRadius: "16px",
                cursor: "pointer",
                color: "white",
                border: "1px solid white",
                fontFamily: "Poppins",
                fontSize: "14px",
              }}
            >
              {t("Contact")}
            </button>
            <div
              onClick={changeLanguage}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                gap: "8px",
                cursor: "pointer",
                fontSize: "14px",
                fontFamily: "Poppins",
              }}
            >
              <img
                alt={"language-icon"}
                src={isFrench ? FranceFlag : EnglishFlag}
                style={{
                  width: isFrench ? "24px" : "30px",
                  height: "16px",
                }}
              />
              <p
                className={style.header__language}
                style={{
                  color: "white",
                }}
              >
                {isFrench ? "Français" : "English"}
              </p>
            </div>
          </div>
        </div>
        <div onClick={() => setIsOpen(false)} className={style.header__closeBackground} />
        <div className={style.header__rightPart}>
          <button
            onClick={() => window.open("https://www.linkedin.com/jobs/peppermint-company-jobs-worldwide/", "_blank")}
            className={style.header__button}
          >
            {t("Recrutement")}
          </button>
          <button
            onClick={() => {
              navigate("/contact");
            }}
            className={style.header__button}
          >
            {t("Contact")}
          </button>
          <div
            onClick={changeLanguage}
            className={style.header__languageContainer}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              gap: "8px",
              cursor: "pointer",
            }}
          >
            <img
              className={style.header__languageIcon}
              alt={"language-icon"}
              src={isFrench ? FranceFlag : EnglishFlag}
              style={{
                width: isFrench ? "24px" : "24px",
                height: "16px",
              }}
            />
            <p
              className={style.header__language}
              style={{
                color: scrollTop > 50 && !props.isSecond ? "#0F3C50" : "white",
              }}
            >
              {isFrench ? "Français" : "English"}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
