import React from "react";
import HomeHero from "../../Components/HomeHero";
import Mission from "../../Components/Mission";
import Proposal from "../../Components/Proposal";
import Approach from "../../Components/ApproachSection";
import Practices from "../../Components/Practices"
import OurTeam from "../../Components/OurTeam";
import ClientVoices from "../../Components/ClientVoices";
import OurClients from "../../Components/OurClients";
import ContactUsSection from "../../Components/ContactUsSection";

const Home = () => {
    return (
        <div
            style={{
                maxWidth: "100vw",
                overflow: "hidden"
            }}
        >
            <HomeHero/>
            <Mission/>
            <Proposal />
            <Approach/>
            <Practices />
            <OurTeam />
            <ClientVoices />
            <OurClients />
            <ContactUsSection/>
        </div>
    );
};

export default Home;
