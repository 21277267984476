import style from "./style.module.css";
import arrow from "../../Assets/svg/arrow.svg";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { ReactComponent as BgL } from "../../Assets/svg/footer-bg-l.svg";
import { ReactComponent as BgR } from "../../Assets/svg/footer-bg-r.svg";
import { ReactComponent as BgT } from "../../Assets/svg/footer-bg-t.svg";
import { ReactComponent as BgB } from "../../Assets/svg/footer-bg-b.svg";

export default function ContactUsSection() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className={style.contactUsHomeSection}>
      <Box className={style.bgL}>
        <BgL />
      </Box>
      <Box className={style.bgR}>
        <BgR />
      </Box>
      <Box className={style.bgT}>
        <BgT />
      </Box>
      <Box className={style.bgB}>
        <BgB />
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          maxWidth: { xs: "350.4vw", sm: "82.125vw", lg: "1314px" },
          margin: "0 auto",
          zIndex: 1,
        }}
      >
        <div className={style.contactUsHomeSection__titleContainer}>
          <h1 className={style.contactUsHomeSection__title}>
            {t("Contactez-nous")}
          </h1>
          <p className={style.contactUsHomeSection__description}>
            {t(
              "Confiez nous votre e-mail et nous vous contacterons dans les 24h :"
            )}
          </p>
        </div>
        <div className={style.contactUsHomeSection__contactUsContainer}>
          <div
            className={style.contactUsHomeSection__formContainer}
            onClick={() => navigate("/contact")}
            style={{ cursor: "pointer" }}
          >
            <p>{t("Notre formulaire de contact")}</p>
          </div>
          <div className={style.contactUsHomeSection__iconContainer}>
            <img
              className={style.contactUsHomeSection__icon}
              onClick={() => navigate("/contact")}
              src={arrow}
              alt={"arrow-icon"}
            />
          </div>
        </div>
      </Box>
    </div>
  );
}
