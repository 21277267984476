import React, { useEffect } from 'react';

const AnalyticsInjector = () => {
  const trackingId = "G-XTQGF21QPQ";

  useEffect(() => {
    if (trackingId) {
      (function() {
        const script = document.createElement('script');
        script.async = true;
        script.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;
        document.body.appendChild(script);
      })();

      window.dataLayer = window.dataLayer || [];

      function gtag(){window.dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', trackingId);
    }
  }, [trackingId]);

  return null;
}

export default AnalyticsInjector;
