import React from "react";
import { Button } from "@mui/material";
import style from "./style.module.css";
import { useNavigate } from "react-router-dom";
import {useTranslation} from "react-i18next";

const HomeHero = () => {
  const navigate = useNavigate();
  const {t} = useTranslation()

  return (
    <div className={style.homeHero}>
      <div className={style.homeHero__container}>
        <div className={style.homeHero__content}>
          <h1 className={style.homeHero__heading}>
            <span className={[style.homeHero__headingText, style.sp].join(" ")}>
              &nbsp;{t("Le digital ")} <br /> &nbsp;{t("comme levier")}&nbsp;
            </span>
            <span className={[style.homeHero__headingText, style.pc].join(" ")}>
              &nbsp;{t("Le digital comme levier")}&nbsp;
            </span>
          </h1>
          <h1 className={style.homeHero__title}>
            {t("de performance et de la stratégie d'entreprise")}
          </h1>
          <Button
            className={style.homeHero__button}
            variant="contained"
            color="secondary"
            sx={{
              borderRadius: { xs: "40vw", sm: "9.375vw", lg: "150px" },
              color: "#0F3C50",
              fontWeight: "600",
              fontSize: { xs: "3.733333333vw", sm: "0.875vw", lg: "14px" },
              textTransform: "none",
              px: { xs: "8vw", sm: "1.875vw", lg: "30px" },
            }}
            onClick={() => navigate("/contact")}
          >
            {t("Contactez-nous")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default HomeHero;
