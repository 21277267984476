import * as React from "react";
import { styled } from "@mui/material/styles";
import styles from "./style.module.css";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import {useTranslation} from "react-i18next";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    sx={{
      "& .MuiAccordionSummary-content": {
        margin: { xs: "3.2vw 0", sm: "0.75vw 0", lg: "12px 0" },
        ml: { xs: "2.133333333vw", sm: "0.5vw", lg: "8px" },
      },
    }}
    expandIcon={
      <ArrowForwardIosSharpIcon
        sx={{
          fontSize: { xs: "6.4vw", sm: "1.5vw", lg: "24px" },
          color: "#0F3C50",
        }}
      />
    }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "#fff",
  height: { xs: "13.33333333vw", sm: "3.125vw", lg: "50vw" },
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  // padding: { xs: "4.266666667vw", sm: "1vw", lg: "16px" },
}));

export default function Expandable({ image, title, description }) {
  const [expanded, setExpanded] = React.useState("panel1");
  const {t} = useTranslation()

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary
          aria-controls="panel2d-content"
          id="panel2d-header"
          sx={{
            pl: 0,
            minHeight: { xs: "17.86666667vw", sm: "4.1875vw", lg: "67px" },
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: "4.8vw", sm: "1.125vw", lg: "18px" },
              fontWeight: "600",
              color: "#0F3C50",
              alignSelf: "center",
              ml: { xs: "4.266666667vw", sm: "1vw", lg: "16px" },
            }}
          >
            {t(title)}
          </Typography>
          {expanded === "panel2" && (
            <img
              src={image}
              alt={`${title} logo`}
              className={styles["expanded-logo"]}
            />
          )}
        </AccordionSummary>
        <AccordionDetails
          sx={{
            padding: { xs: "4.266666667vw", sm: "1vw", lg: "16px" },
          }}
        >
          <Typography
            sx={{
              borderLeft: {
                xs: "0.5333333333vw solid #0F3C50",
                sm: "0.125vw solid #0F3C50",
                lg: "2px solid #0F3C50",
              },
              margin: { xs: "4.266666667vw 0", sm: "1vw 0", lg: "1em 0" },
              marginLeft: { xs: "6.4vw", sm: "1.5vw", lg: "24px" },
              padding: { xs: "0 6.4vw", sm: "0 1.5vw", lg: "0 1.5em" },
              fontWeight: "400",
              fontSize: { xs: "4.266666667vw", sm: "1vw", lg: "16px" },
              color: "#0F3C50",
              ml: { xs: "1.6vw", sm: "0.375vw", lg: "6px" },
            }}
          >
            {t(description)}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
