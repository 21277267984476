import React from "react";
import styles from "./style.module.css";
import { ReactComponent as CompanyLogo } from "../../Assets/svg/companyLogo.svg";
import { ReactComponent as LinkedInLogo } from "../../Assets/svg/linkedin-square.svg";
import PeppermintLogo from "../../Assets/Images/peppermint-logo-image.png";
import { useNavigate } from "react-router-dom";
import { Box, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function Footer() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className={styles["container"]}>
      <div className={styles["footer-row"]}>
        <Grid container item xs={12} spacing={{ xs: 0, sm: "5.625vw", md: "90px" }} sx={{ alignItems: "center" }}>
          <Grid item xs={12} sm={3}>
            <>
              {/* section 1 */}
              <div className={`${styles["footer-col"]} ${styles["logo"]} ${styles["section-1"]}`}>
                <img src={PeppermintLogo} className={styles.footerLogoImage} />
                <div className={styles["sp"]}>
                  <div className={styles["social-media"]}>
                    <a href="https://www.linkedin.com/company/peppermint-company/" target="_blank">
                      <LinkedInLogo />
                    </a>
                  </div>
                </div>
                {/* company address */}
                <div className={`${styles["company-address"]}`}>
                  <div className={styles["tag-line-text"]}>
                    <p className={styles["company-address-p"]}>
                      Peppermint Company
                      <br />
                      <span className={styles["normal-text"]}>
                        8 avenue Gourgaud
                        <br />
                        75017 Paris
                      </span>
                    </p>
                  </div>
                  <Box
                    sx={{
                      width: { xs: "6.4vw", sm: "1.5vw", lg: "24px" },
                    }}
                    className={styles["pc"]}
                  >
                    <div className={styles["social-media"]}>
                      <a href="https://www.linkedin.com/company/peppermint-company/" target="_blank">
                        <LinkedInLogo />
                      </a>
                    </div>
                  </Box>
                  <p className={styles["semi-bold-text"]}>© 2022, Peppermint</p>
                </div>
              </div>
            </>
          </Grid>
          <Grid item xs={12} sm={3}>
            <>
              {/* section 2 */}
              <Box className={`${styles["footer-col"]} ${styles["section-2"]}`} sx={{ minHeight: { lg: "180px", sm: "11.25vw", xs: "48vw" } }}>
                <h3 className={styles["footer-h3"]}>{t("Nous rejoindre")}</h3>
                <Box className={styles["normal-text"]} sx={{ minHeight: { lg: "100px", sm: "6.25vw", xs: "fit-content" } }}>
                  <p>
                    {t(
                      "Nous recrutons en permanence les meilleurs talents contributeurs dans toutes les disciplines qui font de nos projets des succès."
                    )}
                  </p>
                </Box>
                <button
                  onClick={() => window.open("https://www.linkedin.com/jobs/peppermint-company-jobs-worldwide/", "_blank")}
                  className={`${styles["outline-button"]} ${styles["outline-join"]}`}
                  style={{ fontFamily: "poppins", fontSize: '14px' }}
                >
                  {t("Voir les offres")}
                </button>
              </Box>
            </>
          </Grid>
          <Grid item xs={12} sm={3}>
            <>
              {/* section 3 */}
              <Box className={`${styles["footer-col"]} ${styles["section-3"]}`} sx={{ minHeight: { lg: "180px", sm: "11.25vw", xs: "48vw" } }}>
                <h3 className={styles["footer-h3"]}>Contact</h3>
                <Box
                  sx={{
                    width: {
                      xs: "65.33333333vw",
                      sm: "15.3125vw",
                      lg: "245px",
                    },
                    minHeight: { lg: "100px", sm: "6.25vw", xs: "fit-content" },
                  }}
                  className={styles["normal-text"]}
                >
                  <p>{t("Venez partager vos projets : nous adorons !")}</p>
                  <p>{t("Venez prendre un café pour échanger sur nos métiers !")}</p>
                </Box>
                <button
                  className={`${styles["outline-button"]} ${styles["outline-contact"]}`}
                  onClick={() => navigate("/contact")}
                  style={{ fontFamily: "poppins", fontSize: '14px' }}
                >
                  {t("Contactez-nous")}
                </button>
              </Box>
            </>
          </Grid>
          <Grid item xs={12} sm={3}>
            <>
              {/* section 4 */}
              <Box
                className={`${styles["footer-col"]} ${styles["normal-text"]} ${styles["section-4"]}`}
                sx={{ mt: { sx: "4.266666667vw", sm: "1vw", lg: "16px" } }}
              >
                <div style={{ marginBottom: "30px" }}>
                  <p>
                    <a onClick={() => navigate("/")} style={{ cursor: "pointer" }}>
                      {t("Accueil")}
                    </a>
                    <br />
                    <a onClick={() => navigate("/about-us")} style={{ cursor: "pointer" }}>
                      {t("Qui sommes-nous")}
                    </a>
                    <br />
                    <a onClick={() => navigate("/contact")} style={{ cursor: "pointer" }}>
                      {t("Contact")}
                    </a>
                  </p>
                </div>
                <div>
                  <p>
                    <a onClick={() => navigate("/cookies-policy")} style={{ cursor: "pointer" }}>
                      {t("Politique des cookies")}
                    </a>
                    <br />
                    <a onClick={() => navigate("/privacy-policy")} style={{ cursor: "pointer" }}>
                      {t("Politique de confidentialité")}
                    </a>
                    <br />
                    <a onClick={() => navigate("/cgu")} style={{ cursor: "pointer" }}>
                      {t("CGU")}
                    </a>
                  </p>
                </div>
              </Box>
            </>
          </Grid>
        </Grid>
        {/* section 5 */}
        <div className={`${styles["address-mobile"]}`}>
          <div className={styles["tag-line-text"]}>
            <p className={styles["company-address-p"]}>Peppermint Company</p>
            <div className={styles["normal-text"]}>
              <p>8 avenue Gourgaud </p>
              <p>75017 Paris</p>
            </div>
          </div>
          <p className={styles["semi-bold-text"]}>© 2022, Peppermint</p>
        </div>
      </div>
    </div>
  );
}
