import React from "react";
import style from "./style.module.css";
import Cover from "../../Assets/Images/mission-cover.jpg";
import {useTranslation} from "react-i18next";

const Mission = () => {
  const {t} = useTranslation()

  return (
    <div className={style.mission}>
      <div className={style.mission__container}>
        <figure className={style.mission__media}>
          <img className={style.mission__image} src={Cover} alt="Mission" />
        </figure>
        <div className={style.mission__text}>
          <h1 className={style.mission__textTitle}>{t("Notre mission")}</h1>
          <p className={style.mission__textDescription}>
            {t("Nous collaborons avec nos clients pour créer et délivrer des solutions qui ont du sens et génèrent des revenus à court terme et de la croissance à long terme.")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Mission;
