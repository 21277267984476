import React from "react";
import style from "./style.module.css";
import { Box, Grid } from "@mui/material";

// import Swiper core and required modules
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import {useTranslation} from "react-i18next";

const Proposal = () => {
  const {t} = useTranslation()
  const Item01 = () => {
    return (
      <div>
        <h2 className={style.title}>
          {t("Digitalisation :")}<br />
          {t("levier de performance")}
        </h2>
        <p className={style.description}>
          <strong>
            {t("Automatisation")}
            <br />
          </strong>
          &gt;&nbsp;{t("Tâches à faible valeur ajoutée : ")}&nbsp;{t("moins de coûts")}
          <br />
          <br />
          <strong>
            {t("Nouveaux services")}
            <br />
          </strong>
          &gt;&nbsp;{t("Nouveaux marchés adressés : ")}&nbsp;{t("plus de ")} {t("revenus")}
          <br />
          <br />
          <strong>
            {t("GaaS Factory")}
            <br />
          </strong>
          &gt; {t("Growth-As-A-Service : plateforme spécifique par client de génération de leads et de ")}
          {/* <br /> */}
          {t("pilotage de la performance commerciale ")}
          {/* <br /> */}
          {t("(KPIs coûts/revenus marge)")}
        </p>
      </div>
    );
  };

  const Item02 = () => {
    return (
      <div>
        <h2 className={style.title}>
          {t("Croissance")}
          <br />
          &amp; {t("Transformation")}
        </h2>
        <p className={style.description}>
          <strong>
            {t("Rénovation de core business")} <br />
          </strong>
          &gt; {t("Cross disciplinarité, delivery factories")}
          <br />‍<br />
          <strong>{t("Diversification")}</strong>
          <br />
          &gt; {t("Création et lancement de nouveaux services sans changer le modèle opérant")}
          <br />
          <br />
          <strong>
            {t("Transformation")}
            <br />
          </strong>
          &gt; {t("Méthode, mindset et organisation")}
        </p>
      </div>
    );
  };

  const Item03 = () => {
    return (
      <div>
        <h2 className={style.title}>
          {t("Accélération")}
          <br />
          {t("De Go-To-Market")}
        </h2>
        <p className={style.description}>
          <strong>
            {t("Business models, RoadMap")}
            <br />
            {t("et organisation")}
          </strong>
          <br />
          &gt; {t("Priorisation par la data")}
          <br />
          <br />
          <strong>{t("Go-To-Market")} </strong>
          <br />
          &gt; {t("Un nouveau service live en moins ")}
          <br />
          {t("de 4 mois et passage à l’échelle en ")}
          <br />
          {t("moins de 12 mois")}
        </p>
      </div>
    );
  };
  return (
    <div id="proposal" className={style.proposal}>
      <div className={style.pc}>
        <div className={style.proposal__container}>
          <div className={style.container}>
            <h1 className={style.proposal__heading}>
              {t("Notre proposition de valeur")}
            </h1>
          </div>
          <div className={style.container}>
            <Grid
              container
              spacing={{ xs: "13.33333333vw", sm: "3.125vw", lg: "50px" }}
            >
              <Grid item xs={12} sm={4}>
                <Item01 />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Item02 />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Item03 />
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
      <div className={style.sp}>
        <div className={style.container}>
          <h1 className={style.proposal__heading}>
            {t("Notre proposition de valeur")}
          </h1>
        </div>
        <Swiper
          modules={[Pagination]}
          spaceBetween={50}
          slidesPerView={1}
          pagination={{ clickable: true }}
        >
          <SwiperSlide>
            <Box
              sx={{
                px: { xs: "7.466666667vw", sm: "1.75vw", lg: "28px" },
                pb: { xs: "7.466666667vw", sm: "1.75vw", lg: "28px" },
              }}
            >
              <Item01 />
            </Box>
          </SwiperSlide>
          <SwiperSlide>
            <Box
              sx={{
                px: { xs: "7.466666667vw", sm: "1.75vw", lg: "28px" },
                pb: { xs: "7.466666667vw", sm: "1.75vw", lg: "28px" },
              }}
            >
              <Item02 />
            </Box>
          </SwiperSlide>
          <SwiperSlide>
            <Box
              sx={{
                px: { xs: "7.466666667vw", sm: "1.75vw", lg: "28px" },
                pb: { xs: "7.466666667vw", sm: "1.75vw", lg: "28px" },
              }}
            >
              <Item03 />
            </Box>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};

export default Proposal;
