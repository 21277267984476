import communicationImg from '../Assets/Images/communication.png';
import strategyImg from '../Assets/Images/strategy.png';
import POImg from '../Assets/Images/PO.png';
import designImg from '../Assets/Images/design.png';
import growthHackingImg from '../Assets/Images/growth-hacking.png';
import dataImg from '../Assets/Images/data.png';
import technologyImg from '../Assets/Images/technology.png';
import squadMasterImg from '../Assets/Images/squad-master.png';

export const cards = [
    {
        title: 'Vision métier',
        description: 'Nos experts métiers dans les domaines de nos clients apportent des connaissances accumulées sur le marché que les clients ne connaissent en général pas de manière aussi complète.',
        image: communicationImg,
    },
    {
        title: 'Strategy',
        description: 'La vision conseil / stratégique donne les directions qui seront ensuite validées par une approche centrée utilisateur, qualitative et quantitive.',
        image: strategyImg,
    },
    {
        title: 'Product Management',
        description: 'L’excellence dans la gestion de produit est une condition indispensable à la réussite d’un projet digital.',
        image: POImg,
    },
    {
        title: 'Design',
        description: 'Le design de produits numériques représente le product fit, la meilleure intéraction de l’utilisateur avec le service. \nRecherche, prétotypage, atomic design, designOps n’ont pas de secrets pour eux.',
        image: designImg,
    },
    {
        title: 'Growth Hacking',
        description: 'Assurer l’adoption interne, la croissance de chiffre d’affaires, la rentabilité ou la prise de parts de marché est leur obsession avec une approche ROIste systématique.',
        image: growthHackingImg,
    },
    {
        title: 'Data',
        description: 'La pertinence et la performance de la data sont les bases d’un dispositif digital réussi. \n En augmentant notre vision, la data est le prérequis des meilleures décisions.',
        image: dataImg,
    },
    {
        title: 'Technology',
        description: 'En véritables enablers de la vision produit, les équipes techniques sont technologies agnostiques, ce qui ne nous empêche pas d’avoir des pôles d’expertise en Java, .Net mais également en technologie open source, PHP, Node JS, Flutter, React...',
        image: technologyImg,
    },
    {
        title: 'Squad mastering',
        description: 'Le squad master a un rôle central. Il est à la fois le garant de la bonne application de la méthode, en charge du séquencement des opérations pour assurer le delivery et le repère du Product Owner dans la faisabilité du backlog.',
        image: squadMasterImg,
    },
]
