import React from "react";
import style from "./style.module.css";
import ContactUs from "../../Components/ContactUs";


const ContactUsPage = () => {
  return (
    <div className={style.container}>
        <ContactUs/>
    </div>
  );
};

export default ContactUsPage;
