import React from "react";
import styles from "./style.module.css";
import OurTeamMap from "../../Assets/Images/our-team-map.png";
import CambodiaImg from "../../Assets/Images/cambodia.png";
import ParisImg from "../../Assets/Images/paris-contain.png";
import NewParisImg from "../../Assets/Images/newParisTeamImg.png";
import TunisieImg from "../../Assets/Images/tunisie.png";
import SophiaAntipolisImg from "../../Assets/Images/sophia-antipolis.png";
import { ReactComponent as MarkerIcon } from "../../Assets/svg/marker.svg";
import { Box, Grid } from "@mui/material";

// import Swiper core and required modules
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { useTranslation } from "react-i18next";

export default function OurTeam() {
  const { t } = useTranslation();
  const TeamImage = ({ src, maxWidth, height, title, location }) => {
    return (
      <Box
        sx={{
          position: "relative",
          width: "100%",
          maxWidth: maxWidth,
          height: height,
          minHeight: height,
          borderRadius: { xs: "8.533333333vw", sm: "2vw", lg: "32px" },
        }}
      >
        <img className={styles.our_team_img} src={src} alt="" />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            position: "absolute",
            top: "0px",
            height: "100%",
            justifyContent: "space-between",
          }}
        >
          <div className={styles.our_team_title}>{t(title)}</div>
          <Box
            className={styles.our_team_location_text}
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              marginTop: "auto",
              marginBottom: { xs: "5.333333333vw", sm: "1.25vw", lg: "20px" },
            }}
          >
            <div className={styles.our_team_marker}>
              <MarkerIcon style={{ flexShrink: 0 }} />
            </div>
            <div
              className={styles.our_team_text}
              dangerouslySetInnerHTML={{ __html: location }}
            />
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <div id="our-team" className={styles.our_team}>
      <div className={styles.our_team_container}>
        <div className={styles.our_team_header}>
          <div className={styles.sp}>
            <h2 className={styles.our_team_section_title}>
              {t("120 personnes sur")} <br />
              {t("3 continents")}
            </h2>
          </div>
          <div className={styles.pc}>
            <h2 className={styles.our_team_section_title}>
              {t("120 personnes sur 3 continents")}
            </h2>
          </div>
          <p className={styles.our_team_section_desc}>
            {t(
              "Nos équipes internes dans nos filiales à l’étranger sont certifiées sur nos méthodes."
            )}
          </p>
        </div>
        <div className={styles.our_team_grid}>
          <Grid container spacing={{ sm: "2vw", lg: "32px" }}>
            {itemData.map((data, index) => (
              <Grid item xs={6}>
                <TeamImage
                  src={index === 0 ? NewParisImg : data.img}
                  height={{ sm: "25vw", lg: "400px" }}
                  maxWidth={{ sm: "39.9375vw", lg: "639px" }}
                  title={data.title}
                  location={data.location}
                />
              </Grid>
            ))}
          </Grid>
        </div>
        <div className={styles.our_team_grid_mobile}>
          <Swiper
            modules={[Pagination]}
            spaceBetween={20}
            slidesPerView={1}
            pagination={{ clickable: true }}
          >
            {itemData.map((data, index) => (
              <SwiperSlide key={index.toString()}>
                <Box sx={{ px: "26px", pb: "52px" }}>
                  <TeamImage
                    src={index === 0 ? NewParisImg : data.img}
                    height="100%"
                    title={data.title}
                    location={data.location}
                  />
                </Box>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className={styles.our_team_map}>
          <img className={styles.our_team_map_img} src={OurTeamMap} />
        </div>
      </div>
    </div>
  );
}

function srcset(image, size, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

const itemData = [
  {
    img: ParisImg,
    title: "Peppermint France (Paris)",
    location: "<div>8 avenue Gourgaud&nbsp;<br>75017 Paris</div>",
    rows: 5,
    cols: 10,
  },
  {
    img: TunisieImg,
    title: "Peppermint Tunisie \n (Tunis)",
    location:
      "<div>Rue du Lac Van, 1053, Les berges du Lac 1,<br>Tunis</div>",
    rows: 1.5,
    cols: 3,
  },
  {
    img: SophiaAntipolisImg,
    title: "Peppermint France \n (Sophia Antipolis)",
    location:
      "<div>Spaces - Les Templiers, 950 Route des Colles<br/>06140 Biot</div>",
    rows: 1.5,
    cols: 3,
  },
  {
    img: CambodiaImg,
    title: "Peppermint Cambodge \n (Phnom Penh)",
    location:
      "<div>#163 St 192, Teouk Laak 3, Toul Kork,&nbsp;<br>Phnom Penh, Cambodia</div>",
    rows: 1.5,
    cols: 3,
  },
];
