import React from "react";
import { Button } from "@mui/material";
import style from "./style.module.css";
import { useNavigate } from "react-router-dom";
import {useTranslation} from "react-i18next";

const AboutUsHero = () => {
  const navigate = useNavigate();
  const {t} = useTranslation()
  return (
    <div className={style.aboutUsHero}>
      <div className={style.aboutUsHeroContainer}>
        <div className={style.aboutUsHeroContent}>
          <h1 className={style.aboutUsHeroHeading}>
            <span className={[style.aboutUsHeroHeadingText, style.sp].join(" ")}>
              &nbsp;{t("Notre mission")}&nbsp;
            </span>
            <span className={[style.aboutUsHeroHeadingText, style.pc].join(" ")}>
              &nbsp;{t("Notre mission")}&nbsp;
            </span>
          </h1>
          <h1 className={style.aboutUsHeroTitle}>
            {t("Nous collaborons avec nos clients pour créer et délivrer des solutions qui ont du sens et génèrent des revenus à court terme et de la croissance à long terme")}
          </h1>
        </div>
      </div>
    </div>
  );
};

export default AboutUsHero;
