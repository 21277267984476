import React from "react";
import style from "./style.module.css";
import {useTranslation} from "react-i18next";

const CGU = () => {
  const {t} = useTranslation()
  return (
    <div id={style.privacy} className={style.container}>
      <h1>{t("Conditions Générales d’Utilisation")}</h1>
      <h2>{t("1. Acceptation des Conditions Générales d’Utilisation")}</h2>
      <p>
        {t("Les présentes Conditions Générales d’Utilisation (”CGU“) énoncent les termes et conditions régissant votre utilisation et votre accès au site web de Peppermint Company (le ”Site“). En accédant et en naviguant sur le Site ou en utilisant et/ou en téléchargeant tout contenu à partir de celui-ci, vous acceptez entièrement les CGU telles que définies ci-dessous.")}
        <br />
        <br />
        {t("Si vous êtes un particulier, vous déclarez et certifiez que vous avez atteint l’âge de la majorité dans la juridiction dans laquelle vous résidez.")}
        <br />
        <br />
        {t("Si vous êtes une personne physique accédant au Site ou l’utilisant au nom ou au profit d’une société, d’un partenariat ou d’une autre entité à laquelle vous êtes associé (une “Organisation”), vous acceptez les présentes CGU en votre nom et au nom de cette Organisation, et vous déclarez et certifiez que vous avez le pouvoir légal de lier cette Organisation aux présentes CGU. Les références à “vous” et “votre” dans les présentes CGU font référence à la fois à la personne qui utilise le site et à l’Organisation concernée.")}
        <br />
        <br />
        {t("Si vous n’acceptez pas d’être lié par les présentes CGU, vous ne devez pas accéder au Site ni l’utiliser.")}
        <br />
        <br />
        {t("Votre accord avec Peppermint Company est constitué des présentes CGU, ainsi que de toutes les conditions supplémentaires publiées sur le Site ou mises à votre disposition par Peppermint Company.")}
        <br />
        <br />
        {t("Peppermint Company se réserve le droit, à sa seule appréciation, de modifier ou de supprimer tout ou partie des présentes CGU à tout moment et sans autre préavis. Dans ce cas, nous indiquerons en haut de cette page la date de la dernière révision des présentes CGU. Votre utilisation du Site après de telles modifications constitue votre acceptation des nouvelles CGU.")}
        <br />
        <br />
        {t("Ces CGU s’appliquent à www.peppermint.company.")}
      </p>
      <h2>{t("2. Objectifs du site")}</h2>
      <p>
        {t("Tous les éléments figurant sur le Site sont fournis à titre informatif uniquement et ne doivent pas être interprétés comme une offre commerciale, une licence, une relation de conseil, fiduciaire ou professionnelle entre vous et Peppermint Company. Aucune information fournie sur ce Site ne doit être considérée comme un substitut à votre recherche personnelle.")}
      </p>
      <h2>{t("3. Liens vers des sites web tiers")}</h2>
      <p>
        {t("Les liens vers des sites Web tiers ne sont fournis qu’à des fins de commodité et n’impliquent aucunement l’approbation ou le soutien par Peppermint Company, même s’ils peuvent contenir le logo de Peppermint Company, car ces sites échappent au contrôle de Peppermint Company. Ainsi, Peppermint Company ne peut être tenu responsable du contenu de tout site lié ou de tout lien qu’il contient. Par conséquent, vous devez vous protéger contre les virus, les vers, les chevaux de Troie et autres programmes potentiellement destructeurs qui peuvent être présents sur des sites tiers. Il vous incombe de vérifier et de respecter les conditions d’utilisation applicables à ces sites Web tiers.")}
        <br />
        <br />
        {t("Vous reconnaissez que le cadrage du Site ou tout autre procédé similaire est interdit.")}
      </p>
      <h2>{t("4. Informations que vous publiez sur le site")}</h2>
      <p>
        {t("Peppermint Company décline toute obligation de surveiller les informations que vous pouvez publier sur son Site.")}
        <br />
        <br />
        {t("Vous ne devez pas publier ou transmettre sur le Site des informations illégales, menaçantes, diffamatoires, obscènes, pornographiques, blasphématoires, ou tout autre matériel qui pourrait constituer ou encourager un comportement qui serait considéré comme une infraction pénale, qui pourrait donner lieu à une responsabilité civile, ou qui violerait de toute autre manière la loi. Peppermint Company coopérera pleinement avec toute autorité chargée de l’application de la loi ou ordonnance judiciaire lui demandant ou lui ordonnant de divulguer l’identité ou d’aider à identifier ou à localiser toute personne ayant publié de telles informations.")}
        <br />
        <br />
        {t("En soumettant des informations ou du Contenu, vous donnez à Peppermint Company une autorisation illimitée et irrévocable d’utiliser, copier, exécuter, montrer, afficher, modifier et transmettre ces informations, ce Contenu ou ces commentaires, y compris toute idée, tout concept ou tout savoir-faire sous-jacent, en tout ou en partie, de quelque manière ou sur quelque support que ce soit. Peppermint Company se réserve le droit d’utiliser ces informations comme bon lui semble.")}
      </p>
      <h2>{t("5. Règles d’usage des réseaux sociaux")}</h2>
      <p>
        {t("Nous souhaitons que nos réseaux sociaux représentent une expérience agréable et que nos communautés soient positives, constructives et solidaires. À cette fin, nous nous réservons le droit de supprimer toute publication enfreignant nos règles internes et de bloquer toute personne qui les enfreint de manière répétée.")}
        <br />
        <br />
        {t("Nous supprimerons les commentaires qui :")}<br />
        <br />
        <span>
          &nbsp;&nbsp;&nbsp;&nbsp;- {t("Diffament, abusent, harcèlent, traquent, menacent ou violent d’une autre manière les droits")} <br />
          {t("légaux d’autrui.")}
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;- {t("Sont racistes, sexistes, homophobes, sexuellement explicites, abusifs ou autrement répréhensibles.")}
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;- {t("Contiennent des jurons ou tout autre langage susceptible d’offenser.")}
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;- {t("Nous savons ou soupçonnons qu’ils peuvent enfreindre la loi ou tolérer ou encourager des activités illégales. Cela inclut la publication, l’affichage, la distribution ou la diffusion de tout contenu ou information diffamatoire, contrefaisant, obscène, indécent, trompeur ou illégal.")}
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;- {t("Font de la publicité pour des produits ou des services dans un but lucratif.")}
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;- {t("Sont faits pour apparaître comme s’ils avaient été postés par quelqu’un d’autre.")}
          <br />
          &nbsp;&nbsp;&nbsp; - {t("Publient de manière répétée le même message, ou n’ont pas de lien avec Peppermint Company -“spam”.")}
        </span>
        <br />
        <br />
        {t("Nous nous réservons le droit de modifier ces règles si nécessaire et de supprimer du contenu pour d’autres motifs non mentionnés dans cette liste.")}
        <br />
        <br />
        {t("En utilisant nos réseaux sociaux, vous acceptez de respecter le présent règlement intérieur et les conditions générales de la plateforme.")}
        <br />
        <br />
        {t("Les commentaires et autres éléments publiés par les utilisateurs de nos réseaux sociaux ne reflètent pas les opinions de Peppermint Company et Peppermint Company ne confirme pas leur exactitude.")}
        <br />
        <br />
        {t("Si vous souhaitez nous signaler toute utilisation inappropriée de ce Site, veuillez contacter : groupe.communication@peppermint.company.")}
      </p>
      <h2>{t("6. Propriété intellectuelle")}</h2>
      <p>
        {t("Ce Site est protégé par des droits de propriété intellectuelle, y compris, mais sans s’y limiter, les marques, les droits d’auteur, les dessins et modèles, les bases de données, etc. et est la propriété exclusive de Peppermint Company.")}
        <br />
        <br />
        {t("Tout contenu, y compris, mais sans s’y limiter, les textes, les données, les graphiques, les images, les sons, les vidéos, les logos, les icônes ou le code html, est protégé par le droit de la propriété intellectuelle et demeure la propriété de Peppermint Company ou de tiers.Vous pouvez copier, télécharger et imprimer ce contenu à des fins personnelles et non commerciales uniquement et conformément aux principes régissant les lois sur la propriété intellectuelle.")}
        <br />
        <br />
        {t("Vous ne devez pas modifier le contenu copié, téléchargé ou imprimé à partir du Site de quelque manière que ce soit. Toute autre utilisation du contenu du Site sans l’autorisation écrite préalable de Peppermint Company est interdite.")}
        <br />
        <br />
        {t("Le non-respect des interdictions susmentionnées peut constituer un acte de contrefaçon et/ou de concurrence déloyale engageant votre responsabilité civile et/ou pénale.")}
        <br />
        <br />
        {t("Les marques déposées de Peppermint Company comprennent, sans s’y limiter, les suivantes : Peppermint Company Europe (agence Paris et Sophia-Antipolis), Peppermint Cambodge, et Peppermint Tunisie.")}
        <br />
        <br />
        {t("Toutes les autres marques non détenues par Peppermint Company qui apparaissent sur le Site sont la propriété de leurs propriétaires respectifs, qui peuvent ou non être affiliés, reliés ou sponsorisés par la Société. Vous devez demander une autorisation spécifique pour utiliser à quelque fin que ce soit l’une des marques commerciales appartenant à Peppermint Company ou à un tiers.")}
      </p>
      <h2>{t("7. Garantie et responsabilité")}</h2>
      <p>
        {t("Tous les éléments contenus dans le Site sont fournis ”en l’état” et sans garantie d’aucune sorte dans la mesure autorisée par la loi applicable. Bien que Peppermint Company fasse des efforts raisonnables pour fournir des informations fiables par le biais de son Site, Peppermint Company ne garantit pas que ce Site est exempt d’inexactitudes, d’erreurs et/ou d’omissions, ou que son contenu est approprié à votre usage particulier ou à jour, et Peppermint Company se réserve le droit de modifier les informations à tout moment sans préavis. Peppermint Company ne garantit pas les résultats découlant de l’utilisation de tout contenu. Vous êtes seul responsable de l’utilisation des éléments contenus dans ce Site. Toute utilisation du Site est de votre seule responsabilité. Vous devez vous conformer à toutes les lois, règles et réglementations applicables.")}
        <br />
        <br />
        {t("Les informations contenues dans ce Site n’étendent ni ne modifient la garantie qui peut vous être appliquée en raison d’une relation contractuelle avec Peppermint Company.")}
        <br />
        <br />
        {t("Peppermint Company ne sera pas responsable des dommages de toute nature, y compris les dommages indirects, consécutifs ou accessoires, les pertes de profits ou de revenus, les interruptions d’activité, les pertes de clientèle, les arrêts de travail, les violations de la sécurité, les virus, les pannes ou dysfonctionnements informatiques, les pertes de données résultant de ou en relation avec l’utilisation, l’incapacité d’utiliser ou la confiance accordée à tout élément contenu dans ce Site ou tout site lié, même si l’une des parties aux présentes CGU est informée de la possibilité de telles pertes.")}
      </p>
      <h2>{t("8. Nullité d’une disposition")}</h2>
      <p>
        {t("Si une disposition ou une partie des présentes CGU est, pour quelque raison que ce soit, considérée comme invalide, illégale ou inapplicable, cette invalidité, illégalité ou inapplicabilité n’affectera pas les autres dispositions des présentes CGU.")}
      </p>
      <h2>{t("9. Non-renonciation")}</h2>
      <p>
        {t("Le fait que Peppermint Company n’exerce pas ou tarde à exercer un droit ou un recours prévu par les présentes CGU ou par la loi ne constitue pas une renonciation au droit ou au recours de Peppermint Company. Si Peppermint Company renonce à faire valoir une violation des présentes CGU, cela ne constitue pas une renonciation à faire valoir une violation ultérieure des CGU.")}
      </p>
      <h2>{t("10. Droit applicable et juridiction")}</h2>
      <p>
        {t("Toute contestation ou réclamation découlant des CGU ou s’y rapportant sera régie par le droit français. Les tribunaux de Paris, France, seront les seuls compétents.")}
      </p>
      <h2>{t("11. Divers")}</h2>
      <p>
        {t("Ce site est édité par :")}<br />
        <br />
        {t("Peppermint Company Europe")}
        <br />
        {t("Société par actions simplifiée à associé unique au capital de 60.000 euros – 898 292 602 RCS Paris")}
        <br />
        {t("Siège social : 8 avenue Gourgaud 75017 Paris – France")}
        <br />
        {t("TVA intracommunautaire : FR53898292602")}
        <br />
        <br />
        {t("Email : groupe.communication@peppermint.company")}
      </p>
    </div>
  );
};

export default CGU;
