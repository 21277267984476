import React from 'react';
import styles from "./style.module.css";
import { Box, Divider, Grid } from '@mui/material';
import {useTranslation} from "react-i18next";

const InNumber = () => {
  const {t} = useTranslation()

  return (
    <div className={styles.aboutUsInNumber}>
        <div className={styles.aboutUsInNumberContainer}>
            <h1 className={styles.aboutUsInNumberHeading}>
                <span className={[styles.aboutUsInNumberHeadingText, styles.sp].join(" ")}>
                    {t("Peppermint en quelques chiffres")}&nbsp;
                </span>
                <span className={[styles.aboutUsInNumberHeadingText, styles.pc].join(" ")}>
                    &nbsp;{t("Peppermint en quelques chiffres")}&nbsp;
                </span>
            </h1>
            <div className={styles.aboutUsInNumberCountsContainer}>
                <div className={styles.aboutUsInNumberStatsSP}>
                    <Grid container item xs={12}>
                        <Grid item xs={12} sm={12}>
                            <div className={styles.aboutUsInNumberCountGrid}>
                                <div className={styles.aboutUsInNumberCountsChildContainer}>
                                    <h1 className={styles.aboutUsInNumberCountsHeading}>
                                        <span className={styles.aboutUsInNumberHeadingText}>
                                            &nbsp;2021&nbsp;
                                        </span>
                                    </h1>
                                    <p className={styles.aboutUsInNumberCountsParagraph}>
                                        <span>{t("Naissance de Peppermint")}</span>
                                    </p>
                                </div>
                                <hr />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <div className={styles.aboutUsInNumberCountGrid}>
                                <div className={styles.aboutUsInNumberCountsChildContainer}>
                                    <h1 className={styles.aboutUsInNumberCountsHeading}>
                                        <span className={styles.aboutUsInNumberHeadingText}>
                                            &nbsp;120&nbsp;
                                        </span>
                                    </h1>
                                    <p className={styles.aboutUsInNumberCountsParagraph}>
                                        <span>{t("Collaborateurs")}</span>
                                    </p>
                                </div>
                                <hr />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <div className={styles.aboutUsInNumberCountGrid}>
                                <div className={styles.aboutUsInNumberCountGrid}>
                                    <div className={styles.aboutUsInNumberCountsChildContainer}>
                                        <h1 className={styles.aboutUsInNumberCountsHeading}>
                                            <span className={styles.aboutUsInNumberHeadingText}>
                                                &nbsp;26&nbsp;
                                            </span>
                                        </h1>
                                        <p className={styles.aboutUsInNumberCountsParagraph}>
                                            <span>{t("Clients nous font confiance")}</span>
                                        </p>
                                    </div>
                                    <hr />
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <div className={styles.aboutUsInNumberCountGrid}>
                                <div className={styles.aboutUsInNumberCountsChildContainer}>
                                    <h1 className={styles.aboutUsInNumberCountsHeading}>
                                        <span className={styles.aboutUsInNumberHeadingText}>
                                            &nbsp;4&nbsp;
                                        </span>
                                    </h1>
                                    <p className={styles.aboutUsInNumberCountsParagraph}>
                                        <span>{t("Entités : France, ")}<br/>{t("Cambodge et Tunisie")}</span>
                                    </p>
                                </div>
                            </div>
                        </Grid>
                    </Grid>    
                </div>

                <div className={styles.aboutUsInNumberStatsPC} >
                    <div className={styles.aboutUsInNumberCountGrid}>
                        <div className={styles.aboutUsInNumberCountsChildContainer}>
                            <h1 className={styles.aboutUsInNumberCountsHeading}>
                                <span className={styles.aboutUsInNumberHeadingText}>
                                    &nbsp;2021&nbsp;
                                </span>
                            </h1>
                            <p className={styles.aboutUsInNumberCountsParagraph}>
                                <span>{t("Naissance de Peppermint")}</span>
                            </p>
                        </div>
                        <hr />
                    </div>
                    <div className={styles.aboutUsInNumberCountGrid}>
                        <div className={styles.aboutUsInNumberCountsChildContainer}>
                            <h1 className={styles.aboutUsInNumberCountsHeading}>
                                <span className={styles.aboutUsInNumberHeadingText}>
                                    &nbsp;120&nbsp;
                                </span>
                            </h1>
                            <p className={styles.aboutUsInNumberCountsParagraph}>
                                <span>{t("Collaborateurs")}</span>
                            </p>
                        </div>
                        <hr />
                    </div>
                    <div className={styles.aboutUsInNumberCountGrid}>
                        <div className={styles.aboutUsInNumberCountsChildContainer}>
                            <h1 className={styles.aboutUsInNumberCountsHeading}>
                                <span className={styles.aboutUsInNumberHeadingText}>
                                    &nbsp;26&nbsp;
                                </span>
                            </h1>
                            <p className={styles.aboutUsInNumberCountsParagraph}>
                                <span>{t("Clients nous font confiance")}</span>
                            </p>
                        </div>
                        <hr />
                    </div>
                    <div className={styles.aboutUsInNumberCountGrid}>
                        <div className={styles.aboutUsInNumberCountsChildContainer}>
                            <h1 className={styles.aboutUsInNumberCountsHeading}>
                                <span className={styles.aboutUsInNumberHeadingText}>
                                    &nbsp;4&nbsp;
                                </span>
                            </h1>
                            <p className={styles.aboutUsInNumberCountsParagraph}>
                                <span>{t("Entités : France, ")}<br/>{t("Cambodge et Tunisie")}</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default InNumber;
