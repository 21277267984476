import {Outlet} from "react-router-dom";
import Header from "../../../Components/Header";
import Footer from "../../../Components/Footer";

const SecondLayout = () => {
    return (
        <>
            <Header isSecond={true}/>
            <Outlet/>
            <Footer/>
        </>
    )
};

export default SecondLayout;
