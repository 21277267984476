import React from "react";
import style from "./style.module.css";
import Cover from "../../../Assets/Images/about-us-mission-cover.png";
import {useTranslation} from "react-i18next";

const AboutUsMission = () => {
  const {t} = useTranslation()
  return (
    <div className={style.aboutUsMainContainer}>
      <div className={style.aboutUsContainer}>
        <figure className={style.aboutUsMedia}>
          <img className={style.aboutUsImage} src={Cover} alt="Mission" />
        </figure>
        <div className={style.aboutUsText}>
          <h1 className={style.aboutUsTextTitle}>{t("Qui sommes-nous ?")}</h1>
          <p className={style.aboutUsTextDescription}>
            {t("Peppermint est née de la rencontre de deux entrepreneurs aguerris qui partagent une vision similaire et complémentaire. La transformation au sein des entreprises est un vœu pieux mal maîtrisé qui engendre plus de déception que de résultats probants.")}
          </p>
          <p className={style.aboutUsTextDescription}>
            {t("Dans l’écosystème où gravitent de multiples acteurs tels que les cabinets de conseil, les agences digitales, les startups studio ou encore les ESN, aucun d’entre eux n’est en mesure d’apporter une réponse satisfaisante de bout en bout à ce besoin vital de transformation au service de la stratégie et la performance des entreprises.")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUsMission;
