import React from "react";
import style from "./style.module.css";
import { Box, Button } from "@mui/material";

import { ReactComponent as ArrowLeft } from "../../Assets/Icons/arrow-left.svg";
import { ReactComponent as ArrowRight } from "../../Assets/Icons/arrow-right.svg";

// import Swiper core and required modules
import { Grid, Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/navigation";
import {useTranslation} from "react-i18next";

const OurClients = () => {
  const {t} = useTranslation()
  const data = [
    {
      company_name: "",
      logo: require("../../Assets/Images/OurClients/client_09.png"),
    },
    {
      company_name: "",
      logo: require("../../Assets/Images/OurClients/client_10.png"),
    },
    {
      company_name: "",
      logo: require("../../Assets/Images/OurClients/client_11.png"),
    },
    {
      company_name: "",
      logo: require("../../Assets/Images/OurClients/client_12.png"),
    },
    {
      company_name: "",
      logo: require("../../Assets/Images/OurClients/client_13.png"),
    },
    {
      company_name: "",
      logo: require("../../Assets/Images/OurClients/client_14.png"),
    },
    {
      company_name: "",
      logo: require("../../Assets/Images/OurClients/client_15.png"),
    },
    {
      company_name: "",
      logo: require("../../Assets/Images/OurClients/client_16.png"),
    },
    {
      company_name: "",
      logo: require("../../Assets/Images/OurClients/client_17.png"),
    },
    {
      company_name: "",
      logo: require("../../Assets/Images/OurClients/client_18.png"),
    },
    {
      company_name: "",
      logo: require("../../Assets/Images/OurClients/client_34.png"),
    },
    {
      company_name: "",
      logo: require("../../Assets/Images/OurClients/client_20.png"),
    },
    {
      company_name: "",
      logo: require("../../Assets/Images/OurClients/client_21.png"),
    },
    {
      company_name: "",
      logo: require("../../Assets/Images/OurClients/client_22.png"),
    },
    {
      company_name: "",
      logo: require("../../Assets/Images/OurClients/client_23.png"),
    },
    {
      company_name: "",
      logo: require("../../Assets/Images/OurClients/client_24.png"),
    },
    {
      company_name: "",
      logo: require("../../Assets/Images/OurClients/client_25.png"),
    },
    {
      company_name: "",
      logo: require("../../Assets/Images/OurClients/client_26.png"),
    },
    {
      company_name: "",
      logo: require("../../Assets/Images/OurClients/client_27.png"),
    },
    {
      company_name: "",
      logo: require("../../Assets/Images/OurClients/client_28.png"),
    },
    {
      company_name: "",
      logo: require("../../Assets/Images/OurClients/client_29.png"),
    },
    {
      company_name: "",
      logo: require("../../Assets/Images/OurClients/client_30.png"),
    },
    {
      company_name: "",
      logo: require("../../Assets/Images/OurClients/client_31.png"),
    },
    {
      company_name: "",
      logo: require("../../Assets/Images/OurClients/client_33.png"),
    },
  ];

  const arrowStyle = {
    display: "flex",
    width: { xs: "7.2vw", sm: "1.6875vw", lg: "27px" },
    height: { xs: "4.533333333vw", sm: "1.0625vw", lg: "17px" },
  };

  return (
    <div id="our-clients" className={style.ourClients}>
      <div className={style.container}>
        <div className={style.ourClients__header}>
          <h1 className={style.ourClients__heading}>{t("Nos clients")}</h1>
          <div className={style.pc}>
            <div className="button-slide-nav">
              <Button className="prev-slide">
                <Box sx={arrowStyle}>
                  <ArrowLeft />
                </Box>
              </Button>
              <Button className="next-slide">
                <Box sx={arrowStyle}>
                  <ArrowRight />
                </Box>
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Swiper
        className={style.slideContainer}
        modules={[Grid, Navigation, Autoplay]}
        speed={1000}
        autoplay={{
          delay: 4000,
        }}
        slidesPerGroup={1}
        spaceBetween={0}
        slidesPerView={1}
        grid={{
          rows: 2,
          fill: "row",
        }}
        centeredSlides={true}
        navigation={{
          nextEl: ".next-slide",
          prevEl: ".prev-slide",
        }}
        breakpoints={{
          600: {
            slidesPerView: 4,
            slidesPerGroup: 4,
            spaceBetween: 0,
            grid: { rows: 2, fill: "row" },
            centeredSlides: false,
          },
        }}
      >
        {data.map((data, idx) => (
          <SwiperSlide key={idx}>
            <Box
              sx={{ px: "20px", pb: "32px" }}
              className={style.clientLogoContainer}
            >
              <Box
                className={style.clientLogo}
                component="img"
                src={data.logo}
                alt={data.company_name}
              />
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className={style.sp}>
        <Box sx={{ mx: "auto", textAlign: "center" }}>
          <div className="button-slide-nav">
            <Button className="prev-slide">
              <Box sx={arrowStyle}>
                <ArrowLeft />
              </Box>
            </Button>
            <Button className="next-slide">
              <Box sx={arrowStyle}>
                <ArrowRight />
              </Box>
            </Button>
          </div>
        </Box>
      </div>
    </div>
  );
};

export default OurClients;
