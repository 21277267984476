import React from "react";
import AboutUsHero from "../../Components/AboutUs/AboutUsHero";
import AboutUsMission from "../../Components/AboutUs/Mission";
import AboutUsInNumber from "../../Components/AboutUs/InNumber";
import AboutUsOurTeam from "../../Components/AboutUs/AboutUsOurTeam";
import AboutUsFounder from "../../Components/AboutUs/AboutUsFounder";

const AboutUs = () => {
  return (
    <div style={{ maxWidth: "100vw", overflowX: "hidden"}}>
        <AboutUsHero />
        <AboutUsMission />
        <AboutUsInNumber />
        <AboutUsFounder />
        <AboutUsOurTeam />
    </div>
  );
};

export default AboutUs;
