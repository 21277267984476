import { useState } from "react";
import style from "./style.module.css";
import { useTranslation } from "react-i18next";
import SmallApproachBackground from "../../Assets/Images/small-approach-background.jpeg";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Grid } from "@mui/material";

import { ReactComponent as ApprochesTopBgSp } from "../../Assets/svg/approches-top-bg_sp.svg";
import { ReactComponent as ApprochesTopBg } from "../../Assets/svg/approches-top-bg.svg";
import { ReactComponent as BgRb } from "../../Assets/svg/bg-rb.svg";
import { ReactComponent as BgRt } from "../../Assets/svg/bg-rt.svg";
import { ReactComponent as BgLb } from "../../Assets/svg/bg-lb.svg";

export default function Index() {
  const { t } = useTranslation();

  const [isFirstOpen, setIsFirstOpen] = useState(false);
  const [isSecondOpen, setIsSecondOpen] = useState(false);

  const handleToggleCollapseFirst = () => {
    setIsFirstOpen(!isFirstOpen);
  };

  const handleToggleCollapseSecond = () => {
      setIsSecondOpen(!isSecondOpen);
  };

  const FirstApproach = () => {
    return (
      <div className={style.approach__firstApproach}>
        <div>
          <div>
            <p className={style.approach__firstApproachTitle}>
              {t("Talent at home")}
            </p>
            <p className={style.approach__secondApproachTitle}>
              {t("Transform inside")}
            </p>
          </div>
          <div
            className={style.approach__titleWithBox}
            style={{ backgroundColor: "#FDAE62" }}
          >
            <p style={{ color: "white", padding: 0, margin: 0 }}>
              {t("Immersion de nos talents dans vos équipes")}
            </p>
          </div>
          <div className={style.approach__firstApproachContent}>
            <div className={style.approach__category}>
              <p className={style.approach__categoryName}>{t("Méthodes : ")}</p>
              <p className={style.approach__categoryContent}>
                {t(
                  "Squad Strategist, Tribe Master, Squad Master, Release Train Engineer, Scrum Master, Coach Agile"
                )}
              </p>
            </div>
            <div className={style.approach__category}>
              <p className={style.approach__categoryName}>{t("Produits :")}</p>
              <p className={style.approach__categoryContent}>
                {t("PO, PPO, Product Manager")}
                <br />
                {t(' PMO, AMOA, MOA')}
              </p>
            </div>
            <div className={style.approach__category}>
              <p
                className={style.approach__categoryName}
                style={{ marginBottom: 0 }}
              >
                {t("Growth :")}
              </p>
              <p
                className={style.approach__categoryContent}
                style={{ marginBottom: 0 }}
              >
                {t(
                  "SEO, SEA, Growth Hacker, Community Manager, Affiliation, Strategist, E-Commerce Manager"
                )}
              </p>
            </div>
            <div
              className={style.approach__category}
              style={{ display: "flex", flexDirection: "column" }}
            >
              <p
                className={style.approach__categoryName}
                style={{ padding: 0, margin: 0 }}
              >
                {t("Digital")}
              </p>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <p
                  className={style.approach__categoryName}
                  style={{ padding: 0, margin: 0 }}
                >
                  {t("Marketing :")}
                </p>
                <p
                  className={style.approach__categoryContent}
                  style={{ marginTop: 0 }}
                >
                  {t(
                    "Digital Marketing Manager, Product Marketing Manager, Category Manager, CRM Manager, \nE-Merchandising Manager, Traffic Manager, Brand Content Manager"
                  )}
                </p>
                </div>
            </div>
            <div className={style.approach__category}>
              <p className={style.approach__categoryName}>{t("Tech :")}</p>
              <p className={style.approach__categoryContent}>
                {t("DevOps ou Dev, ")}
                <br />
                {t("Java, PHP, Ruby, React, Python...")}
              </p>
            </div>
            <div className={style.approach__category}>
              <p className={style.approach__categoryName}>{t("Data :")}</p>
              <p className={style.approach__categoryContent}>
                {t(
                  "Data Engineer, Data Ops, Data Governance, Machine Learning Engineer, Data Analyst"
                )}
              </p>
            </div>
            <div className={style.approach__category}>
              <p className={style.approach__categoryName}>{t("Design :")}</p>
              <p className={style.approach__categoryContent}>
                {t("UX/UI Designer")}
              </p>
            </div>
          </div>
        </div>
        <div className={style.approach__firstApproachMobile}>
          <div>
            {isFirstOpen ? (
              <div style={{ padding: "20px 0" }}>
                <div className={style.approach__category}>
                  <p className={style.approach__categoryName}>
                    {t("Méthodes : ")}
                  </p>
                  <p className={style.approach__categoryContent}>
                    {t(
                      "Squad Strategist, Tribe Master, Squad Master, Release Train Engineer, Scrum Master, Coach Agile"
                    )}
                  </p>
                </div>
                <div className={style.approach__category}>
                  <p className={style.approach__categoryName}>
                    {t("Produits :")}
                  </p>
                  <p className={style.approach__categoryContent}>
                    {t("PO, PPO, Product Manager, ")}
                    <br />
                    {t("PMO, AMOA, MOA")}
                  </p>
                </div>
                <div className={style.approach__category}>
                  <p className={style.approach__categoryName}>
                    {t("Growth :")}
                  </p>
                  <p className={style.approach__categoryContent}>
                    {t(
                      "SEO, SEA, Growth Hacker, Community Manager, Affiliation, Strategist, \n E-Commerce Manager"
                    )}
                  </p>
                </div>
                <div
                  className={style.approach__category}
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <p
                    className={style.approach__categoryName}
                    style={{ padding: 0, margin: 0 }}
                  >
                    {t("Digital")}
                  </p>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <p
                      className={style.approach__categoryName}
                      style={{ padding: 0, margin: 0 }}
                    >
                      {t("Marketing :")}
                    </p>
                    <p
                      className={style.approach__categoryContent}
                      style={{ margin: 0 }}
                    >
                      {t(
                        "Digital Marketing Manager, Product Marketing Manager, Category Manager, CRM Manager, \n E-Merchandising Manager, Traffic Manager, Brand Content Manager"
                      )}
                    </p>
                  </div>
                </div>
                <div className={style.approach__category}>
                  <p className={style.approach__categoryName}>{t("Tech :")}</p>
                  <p className={style.approach__categoryContent}>
                    {t("DevOps ou Dev, ")}
                    <br />
                    {t("Java, PHP, Ruby, React, Python...")}
                  </p>
                </div>
                <div className={style.approach__category}>
                  <p className={style.approach__categoryName}>{t("Data :")}</p>
                  <p className={style.approach__categoryContent}>
                    {t(
                      "Data Engineer, Data Ops, Data Governance, Machine Learning Engineer, Data Analyst"
                    )}
                  </p>
                </div>
                <div className={style.approach__category}>
                  <p className={style.approach__categoryName}>
                    {t("Design :")}
                  </p>
                  <p className={style.approach__categoryContent}>
                    {t("UX/UI Designer")}
                  </p>
                </div>
              </div>
            ) : (
              <Box
                component="p"
                sx={{
                  fontWeight: 700,
                  padding: { xs: "5.333333333vw 0", sm: "1.25vw 0" },
                }}
              >
                {t(
                  "Méthodes, Produits, Growth, Digital Marketing, Tech et Data, Design"
                )}
              </Box>
            )}
          </div>
        </div>
        <div
          className={style.collapseFirstContentMobile}
          onClick={handleToggleCollapseFirst}
        >
          {isFirstOpen ? (
            <ExpandLessIcon
              sx={{
                fontSize: { xs: "10.66666667vw", sm: "2.5vw", lg: "40px" },
              }}
            />
          ) : (
            <ExpandMoreIcon
              sx={{
                fontSize: { xs: "10.66666667vw", sm: "2.5vw", lg: "40px" },
              }}
            />
          )}
        </div>
      </div>
    );
  };

  const SecondApproach = () => {
    return (
      <div className={style.approach__secondApproach}>
        <div>
          <div>
            <p className={style.approach__firstApproachTitle}>
              {t("Solution")}
            </p>
            <p className={style.approach__secondApproachTitle}>
              {t("Make, Buy, Partner")}
            </p>
          </div>
          <div
            className={style.approach__titleWithBox}
            style={{ backgroundColor: "#3C50A0" }}
          >
            <p style={{ color: "white", padding: 0, margin: 0 }}>
              {t("Une valeur business prédictible, mesurable et pérenne")}
            </p>
          </div>
          <div>
            <p className={style.approach__thirdApproachContent}>
              {t("Make, Buy, Partner de la page blanche aux revenus.")}
            </p>
          </div>
          <div className={style.approach__secondApproachContent}>
            <p style={{ margin: 0 }}>{t("Notre cadre méthodologique se veut :")}</p>
            <Box sx={{ px: { xs: "5.333333333vw", sm: "1.25vw", lg: "20px" } }}>
              {'>'} {t("Cross-disciplinaire")}<br/>
              {'>'} {t("Data-driven")}<br/>
              {'>'} {t("Transparent : comité de levée de fonds interne à chaque itération  validant les investissements et les résultats")} <br/>
              {'>'} {t("Orienté business : création et suivi d’un business case prédictible et mesurable à chaque itération")}<br/>
              {'>'} {t("Opérationnel et métier")}<br/>
              {'>'} {t("Collaboratif")}<br/>
            </Box>
          </div>
          {isSecondOpen && (
            <div className={style.approach__secondApproachContentMobile}>
              <p>{t("Notre cadre méthodologique se veut :")}</p>
              <p
                style={{
                  paddingLeft: "20px",
                  lineHeight: "2",
                }}
              >
                {">"} {t("Cross-disciplinaire")}
                <br />
                {">"} {t("Data-driven")}
                <br />
                {">"} {t("Transparent : comité de levée de fonds interne à chaque itération  validant les investissements et les résultats")}<br />
                {">"} {t("Orienté business : création et suivi d’un business case prédictible et mesurable à chaque itération")}
                <br />
                {">"} {t("Opérationnel et métier")}
                <br />
                {">"} {t("Collaboratif")}
                <br />
              </p>
            </div>
          )}
        </div>
        <div
          className={style.collapseFirstContentMobile}
          onClick={handleToggleCollapseSecond}
        >
          {isSecondOpen ? (
            <ExpandLessIcon
              sx={{
                fontSize: { xs: "10.66666667vw", sm: "2.5vw", lg: "40px" },
              }}
            />
          ) : (
            <ExpandMoreIcon
              sx={{
                fontSize: { xs: "10.66666667vw", sm: "2.5vw", lg: "40px" },
              }}
            />
          )}
        </div>
      </div>
    );
  };

  return (
    <div>
      <ApprochesTopBgSp className={style.approchesTopBgSp} />
      <ApprochesTopBg className={style.approchesTopBg} />
      <div className={style.approach}>
        <BgRb className={style.bgRb} />
        <BgRt className={style.bgRt} />
        <BgLb className={style.bgLb} />
        <div
          style={{
            maxWidth: "1600px",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            zIndex: 1,
          }}
        >
          <div className={style.approach__container}>
            <div className={style.approach__title}>
              <p style={{ margin: 0, padding: 0 }}>{t("2 approches")}</p>
            </div>
            <Grid
              container
              item
              xs={12}
              spacing={{ xs: "7.466666667vw", sm: "1.75vw", lg: "28px" }}
              sx={{
                px: { xs: "8vw", sm: "1.875vw", lg: "30px" },
              }}
            >
              <Grid item xs={12} sm={6} sx={{ display: "flex" }}>
                <FirstApproach />
              </Grid>
              <Grid item xs={12} sm={6} sx={{ display: "flex" }}>
                <SecondApproach />
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
      <div className={style.approach__mobileBackgroundContainer}>
        <img
          alt={"mobile-background"}
          src={SmallApproachBackground}
          className={style.approach__mobileBackground}
        />
      </div>
    </div>
  );
}
