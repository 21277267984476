import React from "react";
import style from "./style.module.css";
import { Box, Grid } from "@mui/material";

// import Swiper core and required modules
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import {useTranslation} from "react-i18next";

const ClientVoices = () => {
  const {t} = useTranslation()

  const data = [
    {
      name: "Olivier Lucas",
      position: "Responsable Stratégie CSE BPCE – Bimpli",
      description:
        "“Nous travaillons depuis pratiquement un an avec Peppermint sur la refonte de notre projet/logiciel de gestion de comptabilité, cœur de notre métier. Peppermint est disponible, à l’écoute des attentes, propose des solutions palliatives et efficaces pour en faire un succès, de la réalisation de projet métier, technique & humain, liée à tous les parcours, qu’ils soient en B2B ou B2C.”",
      image: require("../../Assets/Images/client01.png"),
    },
    {
      name: "Eric Hentges",
      position: "CEO Groupe Eurofeu",
      description:
        "“Peppermint nous accompagne sur la définition et la mise en œuvre de notre stratégie digitale. Les consultants Peppermint combinent une expertise des outils digitaux avec une vision résolument pratique de ce que doit être la croissance du business par le digital. C’est de la stratégie digitale appliquée !\n …et adaptée à notre capacité d’adaptation ainsi qu’à celle de nos clients.“",
      image: require("../../Assets/Images/client02.png"),
    },
    {
      name: "Raphael Hagege",
      position: "CEO Imosys",
      description:
        "“Peppermint est un acteur opérationnel de la transformation digitale avec une compréhension de l’activité de ses clients et une approche pluridisciplinaire (métier, design, dev, marketing). Je les ai choisis car j’étais sûr d’avoir le résultat que je recherchais.“",
      image: require("../../Assets/Images/client03.png"),
    },
  ];

  const PersonCard = ({ item }) => {
    return (
      <div className={style.personCard}>
        <figure className={style.personCard__profileContainer}>
          <img
            className={style.personCard__profile}
            src={item.image}
            alt="client"
          />
        </figure>
        <div className={style.personCard__name}>{item.name}</div>
        <div className={style.personCard__position}>{t(item.position)}</div>
        <div className={style.personCard__description}>{t(item.description)}</div>
      </div>
    );
  };

  return (
    <div id="client-voices" className={style.clientVoices}>
      <div className={style.container}>
        <h1 className={style.clientVoices__heading}>
          {t("Ce qu’ils pensent de nous")}
        </h1>
      </div>
      <div className={style.pc}>
        <div className={style.clientVoices__container}>
          <div className={style.container}>
            <Grid container spacing={{ xs: "5.333333333vw", sm: "2vw", lg: "32px" }}>
              {data.map((data, idx) => (
                <Grid key={idx} item xs={12} sm={4} sx={{ display: "flex" }}>
                  <PersonCard item={data} />
                </Grid>
              ))}
            </Grid>
          </div>
        </div>
      </div>
      <div className={style.sp}>
        <Swiper
          modules={[Pagination]}
          spaceBetween={20}
          slidesPerView={1}
          pagination={{ clickable: true }}
        >
          {data.map((data, idx) => (
            <SwiperSlide key={idx}>
              <Box sx={{ px: "20px", pb: "32px" }}>
                <PersonCard item={data} />
              </Box>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default ClientVoices;
