import React from "react";
import style from "./style.module.css";
import CambodiaImg from "../../../Assets/Images/cambodia.png";
import ParisImg from "../../../Assets/Images/paris.png";
import newParisTeamImg from "../../../Assets/Images/newParisTeamImg.png";
import SophiaAntipolisImg from "../../../Assets/Images/sophia-antipolis.png";
import TunisieImg from "../../../Assets/Images/tunisie.png";
import { ReactComponent as ArrowLeft } from "../../../Assets/Icons/arrow-left.svg";
import { ReactComponent as ArrowRight } from "../../../Assets/Icons/arrow-right.svg";
import { Box, Button } from "@mui/material";

// import Swiper core and required modules
import { Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/navigation";
import {useTranslation} from "react-i18next";

const AboutUsOurTeam = () => {
  const {t} = useTranslation()

  const OurTeam = ({ src, title, description, index }) => (
    <>
      <div className={style.aboutUsOurTeamContainer}>
        <figure className={style.aboutUsOurTeamMedia}>
          <img className={style.aboutUsOurTeamImage} src={src} alt="Our Team" />
        </figure>
        <div className={style.aboutUsOurTeamText}>
          <h1 className={style.aboutUsOurTeamTextTitle}>{title}</h1>
          <div className={style.aboutUsOurTeamDescTextContainer}>
            <div
              className={style.aboutUsOurTeamTextDescription}
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </div>
        </div>
      </div>
      <div
        className={style.aboutUsOurTeamContainerMobile}
        style={{
          backgroundImage: `url(${src})`,
          backgroundPosition: index === 2 ? "5% 50%" : "43% 60%",
        }}
      >
        <div className={style.aboutUsOurTeamText}>
          <h1 className={style.aboutUsOurTeamTextTitle}>{title}</h1>
          <div className={style.aboutUsOurTeamDescTextContainer}>
            <div
              className={style.aboutUsOurTeamTextDescription}
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </div>
        </div>
      </div>
    </>
  );

  const arrowStyle = {
    display: "flex",
    width: { xs: "7.2vw", sm: "1.6875vw", lg: "27px" },
    height: { xs: "4.533333333vw", sm: "1.0625vw", lg: "17px" },
  };

  return (
    <div id="team" className={style.aboutUsOurTeamMainContainer}>
      <Box
        sx={{
          maxWidth: { xs: "350.4vw", sm: "82.125vw", lg: "1314px" },
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <div className={style.aboutUsOurTeamHeader}>
          <h1 className={style.aboutUsOurTeamHeaderTitle}>{t("Nos équipes")}</h1>
          <div className={style.pc}>
            <div className="button-slide-nav">
              <Button className="prev-slide">
                <Box sx={arrowStyle}>
                  <ArrowLeft />
                </Box>
              </Button>
              <Button className="next-slide">
                <Box sx={arrowStyle}>
                  <ArrowRight />
                </Box>
              </Button>
            </div>
          </div>
        </div>
        <Swiper
          modules={[Navigation, Autoplay]}
          spaceBetween={30}
          className="mySwiper"
          speed={1000}
          autoplay={{
            delay: 4000,
          }}
          navigation={{
            nextEl: ".next-slide",
            prevEl: ".prev-slide",
          }}
        >
          {itemData.map((item, index) => {
            return(
              <SwiperSlide key={index.toString()}>
                <OurTeam
                  src={item.image}
                  title={t(item.title)}
                  description={t(item.description)}
                  index={index}
                />
              </SwiperSlide>
            )
          })}
        </Swiper>
        <div style={{ width: "100%", textAlign: "center" }}>
          <div className={style.sp}>
            <Box sx={{ mx: "auto", textAlign: "center" }}>
              <div className="button-slide-nav">
                <Button className="prev-slide">
                  <Box sx={arrowStyle}>
                    <ArrowLeft />
                  </Box>
                </Button>
                <Button className="next-slide">
                  <Box sx={arrowStyle}>
                    <ArrowRight />
                  </Box>
                </Button>
              </div>
            </Box>
          </div>
        </div>
      </Box>
    </div>
  );
};

export default AboutUsOurTeam;

const itemData = [
  {
    image: TunisieImg,
    title: "Team Tunisie",
    description:
      "La qualité de la formation technique tunisienne n’est plus à prouver. Peppermint a ouvert à l’été 2021 une filiale à Sousse, afin d’accélérer le recutement de profils techniques pour ses projets en environnement francophone, sans décalage horaire et à un coût raisonnable. Ils sont de culture full stack que ce soit en environnnement Java, .Net mais également en open source comme PHP, React ou Angular. ",
  },
  {
    image: CambodiaImg,
    title: "Team Cambodge",
    description:
      "Dans un pays qui a récemment accéléré son développement, la formation technologique initiée il y a 20 ans par le Japon, a atteint un niveau d’excellence méconnu en Europe. Bonne maîtrise technologique, engagement inédit, excellente maîtrise de l’anglais et bienveillance généralisée sont les qualités qui nous permettent d’assurer la réussite des projets multi localisés. D’un point de vue technologique, l’open source, le mobile et l’intelligence artificielle sont leurs spécialités.",
  },
  {
    image: newParisTeamImg,
    title: "Team France (Paris)",
    description:
      "Proche des métiers et des enjeux opérationnels, l’équipe France est composée majoritairement de séniors en expertise métier, design de services cross canal, stratégie de marque et produit, growth hacking, gestion de projet et expertise technologique. Nos équipes françaises basées dans le centre de Paris sont au plus proche des enjeux de nos partenaires clients et sont l’interface avec nos filiales à l’étranger.",
  },
  {
    image: SophiaAntipolisImg,
    title: "Team France (Sophia Antipolis)",
    description:
      '<p><span style="white-space:pre-wrap;">Supralog, soci&eacute;t&eacute; technologique bas&eacute;e &agrave; Sophia Antipolis ayant 25 ans d&rsquo;exp&eacute;rience, compte 45 ing&eacute;nieurs issus des meilleures &eacute;coles fran&ccedil;aises composant 3 business lines :<br></span></p><ul><li><span style="white-space:pre-wrap;">Software : une solution logicielle compl&egrave;te totalement personnalisable pour les associations</span></li><li><span style="white-space:pre-wrap;">Technology : externalisation des &eacute;tudes et d&eacute;veloppement ainsi que la r&eacute;alisation de projets d&rsquo;innovation</span></li><li><span style="white-space:pre-wrap;">Consulting : renfort d&rsquo;experts technologiques pour des grands groupes (Air France, Amadeus&hellip;)</span></li></ul>'
  },
];
