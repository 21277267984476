import React, { useEffect, useState } from "react";
import Card from "./Card";
import styles from "./style.module.css";
import { cards } from "../../Constants";
import { Grid } from "@mui/material";
import Expandable from "./Expandable";
import { useTranslation } from "react-i18next";

export default function Practices() {
  const {t} = useTranslation()

  return (
    <div className={styles["practices-container"]}>
      <div className={styles["main-section"]}>
        <p className={styles["practices-h1"]}>{t("Nos practices")}</p>
        <div className={styles["sp"]}>
          <p className={styles["p-tag"]}>
            {t("Nous pilotons tous nos projets par la data et dans une approche centrée utilisateur, systématiquement accompagnée d’un business case (coûts vs revenus) prédictible et mesurable.")}
          </p>
          <p className={styles["p-tag"]}>
            {t("Nous accompagnons nos clients sur tout ou partie de la chaîne d’expertise métier : des nouveaux services opérationnels en passant par la conception, la mise en place de business case, la réalisation technique et la mise sur le marché.")}
          </p>
        </div>
        <div className={styles["pc"]}>
          <p className={styles["p-tag"]}>
            {t("Nous pilotons tous nos projets par la data et dans une approche centrée utilisateur, systématiquement accompagnée d’un business case (coûts vs revenus) prédictible et mesurable. Nous accompagnons nos clients sur tout ou partie de la chaîne d’expertise métier : des nouveaux services opérationnels en passant par la conception, la mise en place de business case, la réalisation technique et la mise sur le marché.")}
          </p>
        </div>
      </div>
      <div className={styles["grid-card"]}>
        <Grid
          container
          item
          xs={12}
          spacing={{ xs: "8.533333333vw", sm: "2vw", lg: "32px" }}
        >
          {cards.map((card, index) => (
            <Grid item xs={12} sm={3} sx={{ display: "flex" }}>
              <Card key={card.title} {...card} />
            </Grid>
          ))}
        </Grid>
      </div>
      <div className={styles["expandable"]}>
        {cards.map((card) => (
          <Expandable key={card.title} {...card} />
        ))}
      </div>
    </div>
  );
}
